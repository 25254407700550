import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useGetGroceryListQuery, useUpdateGroceryListMutation, useRefreshGroceryListMutation } from 'src/store/apiSlice';
import { ToggleableCard } from '../../components/ToggleableCard';
import { Button } from '../../components/Button';

export default function GroceryList() {
    const aisles = ["produce", "butcher", "frozen foods", "dairy", "bakery", "ethnic", "other"];
    const { groceryListId } = useParams();
    const { data: groceryListResponse, isLoading, isError } = useGetGroceryListQuery(groceryListId);
    const [updateGroceryList] = useUpdateGroceryListMutation();
    const [refreshGroceryList] = useRefreshGroceryListMutation();

    const isStale = groceryListResponse?.stale;

    const [groceryList, setGroceryList] = useState({});

    const [toggledRecipes, setToggledRecipes] = useState({});

    const handleRecipeToggle = (recipeId) => (isToggled) => {
        setToggledRecipes(prev => ({ ...prev, [recipeId]: isToggled }));
    };

    const filteredGroceryList = useMemo(() => {
        const activeRecipes = Object.entries(toggledRecipes)
            .filter(([_, isToggled]) => isToggled)
            .map(([recipeId]) => recipeId);

        if (activeRecipes.length === 0) return groceryList;

        return Object.fromEntries(
            Object.entries(groceryList).map(([aisle, items]) => [
                aisle,
                items.filter(item => activeRecipes.includes(item.recipe_id))
            ])
        );
    }, [groceryList, toggledRecipes]);

    useEffect(() => {
        if (groceryListResponse) {
            const groceryListData = groceryListResponse.grocery_list;
            const updatedGroceryList = aisles.reduce((acc, aisle) => {
                acc[aisle] = groceryListData[aisle] || [];
                return acc;
            }, {});
            setGroceryList(updatedGroceryList);
        }
    }, [groceryListResponse]);
    
    if (isLoading) return <div>Loading...</div>;
    if (isError) return <div>Error loading grocery list.</div>;

    const handleInputChange = (key, item_id, event) => {
        const newGroceryList = { ...groceryList };
        const aisleItems = newGroceryList[key];
        const itemIndex = aisleItems.findIndex(item => item.item_id === item_id);

        if (itemIndex !== -1) {
            if (event.target.value === '' && event.key === 'Backspace') {
                // Remove the item if input is empty and backspace is pressed
                newGroceryList[key] = [
                    ...aisleItems.slice(0, itemIndex),
                    ...aisleItems.slice(itemIndex + 1)
                ];
            } else {
                // Update the item normally
                const updatedItem = { ...aisleItems[itemIndex] };
                updatedItem.ingredient_string = event.target.value;
                newGroceryList[key] = [
                    ...aisleItems.slice(0, itemIndex),
                    updatedItem,
                    ...aisleItems.slice(itemIndex + 1)
                ];
            }
        }
        setGroceryList(newGroceryList);
    };

    const handleCheckboxChange = (key, item_id) => {
        const newGroceryList = { ...groceryList };
        const aisleItems = newGroceryList[key];
        const itemIndex = aisleItems.findIndex(item => item.item_id === item_id);
        if (itemIndex !== -1) {
            const updatedItem = { ...aisleItems[itemIndex] };
            const currentStatus = updatedItem.checkbox_status;
            
            // Update the checkbox status cycle
            updatedItem.checkbox_status = 
                currentStatus === 'unchecked' ? 'checked' :
                currentStatus === 'checked' ? 'confirm' : 'unchecked';

            newGroceryList[key] = [
                ...aisleItems.slice(0, itemIndex),
                updatedItem,
                ...aisleItems.slice(itemIndex + 1)
            ];
        }
        setGroceryList(newGroceryList);
    };

    const handleSave = () => {
        updateGroceryList({ groceryListId, groceryList });
    };

    const handleRefresh = () => {
        refreshGroceryList({ groceryListId });
    };

    const handleAddItem = (key) => {
        const newGroceryList = { ...groceryList };
        const newItem = {
            item_id: Math.random().toString(36).substr(2, 9), // generate a unique id
            checkbox_status: 'unchecked',
            ingredient_string: '',
            recipe_id: null
        };
        newGroceryList[key] = newGroceryList[key] ? [...newGroceryList[key]] : [];
        newGroceryList[key].push(newItem);
        setGroceryList(newGroceryList);
    };

    return (
        <div className="max-w-[800px] justify-center mx-auto px-4">
            <div className="flex justify-between items-baseline pt-16 pb-4">
                <div className="flex items-center">
                    <h1 className="text-4xl font-bold mr-4">Grocery List</h1>
                    {isStale && (
                        <Button onClick={handleRefresh} variant="link">Grocery list is stale, refresh grocery list</Button>
                    )}
                </div>
                <Button onClick={handleSave}>Save</Button>
            </div>

            {/* Recipe Toggle Cards */}
            <div className="mb-6">
                <h2 className="text-2xl font-semibold mb-2">Recipes</h2>
                <div className="flex flex-wrap gap-2">
                    {groceryListResponse?.recipes && Object.values(groceryListResponse.recipes).map((recipe) => (
                        <ToggleableCard 
                            key={recipe.recipe_id}
                            className="w-48"
                            handleToggle={handleRecipeToggle(recipe.recipe_id)}
                        >
                            {recipe.headline}
                        </ToggleableCard>
                    ))}
                </div>
            </div>

            {aisles.map((aisle) => {
                const value = filteredGroceryList[aisle] || [];
                const sortOrder = ['unchecked', 'confirm', 'checked'];
                const sortedValue = [...value].sort((a, b) => sortOrder.indexOf(a.checkbox_status) - sortOrder.indexOf(b.checkbox_status));
    
                return (
                    <div key={aisle} className="pb-4">
                        <div>
                            <h3>{`${aisle.charAt(0).toUpperCase() + aisle.slice(1)}`}</h3>
                        </div>
                        {sortedValue.map((item) => (
                            <div className="pb-1" key={item.item_id}>
                                <div className={`flex items-center h-8 rounded ${item.checkbox_status === 'checked' ? 'bg-gray-600' : item.checkbox_status === 'confirm' ? 'border text-orange border-orange' : ''}`}>                                    
                                    <button onClick={() => handleCheckboxChange(aisle, item.item_id)} className="w-6 h-6 flex justify-center items-center rounded">
                                        {item.checkbox_status === 'unchecked' && <i className="far fa-square"></i>}
                                        {item.checkbox_status === 'checked' && <i className="fas fa-check-square"></i>}
                                        {item.checkbox_status === 'confirm' && <i className="fas fa-question"></i>}
                                    </button>
                                    <input type="text" value={item.ingredient_string} onChange={(event) => handleInputChange(aisle, item.item_id, event)} onKeyDown={(event) => {
                                        if (event.key === 'Backspace') {
                                            handleInputChange(aisle, item.item_id, event);
                                        }
                                    }} className="input input-ghost w-full bg-transparent h-8 text-black focus:text-green" />
                                </div>
                            </div>
                        ))}
                        <Button onClick={() => handleAddItem(aisle)} variant="neutral" size="sm" className="mt-2">Add item</Button>
                    </div>
                );
            })}
        </div>
    );
}
import moment from 'moment';
import { Duration } from 'luxon';

const MEAL_TYPE_ORDER = {
  'breakfast': 0,
  'lunch': 1,
  'snack': 2,
  'dinner': 3
};

export function formatDate(date) {
    const mDate = moment(date).startOf('day');
    const today = moment().startOf('day');
    const yesterday = moment().subtract(1, 'day').startOf('day');
    const tomorrow = moment().add(1, 'day').startOf('day');
    const nextWeek = moment().add(1, 'week').startOf('day');

    if (mDate.isSame(today, 'day')) {
        return 'Today';
    } else if (mDate.isSame(tomorrow, 'day')) {
        return 'Tomorrow';
    } else if (mDate.isSame(yesterday, 'day')) {
        return 'Yesterday';
    } else if (mDate.isAfter(today) && mDate.isBefore(nextWeek, 'day')) {
        return 'This ' + mDate.format('dddd');
    } else if (mDate.isAfter(nextWeek) && mDate.isBefore(nextWeek.clone().add(1, 'week'))) {
        return 'Next ' + mDate.format('dddd');
    } else {
        return mDate.format('dddd, MMM Do, YYYY');
    }
}


export function formatDuration(isoDuration) {
    try {
      const duration = Duration.fromISO(isoDuration);
      
      if (!duration.isValid) {
        return null;
      }
  
      const days = duration.as('days');
      const hours = duration.as('hours') % 24;
      const minutes = duration.as('minutes') % 60;
  
      const parts = [
        days >= 1 ? `${Math.floor(days)}d` : '',
        hours >= 1 ? `${Math.floor(hours)}h` : '',
        minutes >= 1 ? `${Math.floor(minutes)}m` : '',
      ].filter(Boolean);
  
      return parts.join(' ') || '0m';
    } catch (error) {
      return null;
    }
  }

export function floatToString(num) {
  if (Number.isInteger(num)) {
    return num.toString();
  }

  // Check common fractions
  const divisors = [2, 3, 4, 8];
  for (const divisor of divisors) {
    // Convert to fraction with specified denominator
    const decimal = num - Math.floor(num);
    const fraction = Math.round(decimal * divisor) / divisor;
    
    if (Math.abs(decimal - fraction) < 0.01) {
      const wholeNum = Math.floor(num);
      const numerator = Math.round(decimal * divisor);
      if (wholeNum > 0) {
        return `${wholeNum} ${numerator}/${divisor}`;
      }
      return `${numerator}/${divisor}`;
    }
  }
  
  return num.toFixed(2);
}

export function formatIngredient(ingredient) {
  if (typeof ingredient === 'string') return ingredient;
  
  const quantity = ingredient.quantity ? floatToString(ingredient.quantity) : '';
  const unit = ingredient.unit_of_measure || '';
  const name = ingredient.parsed_ingredient || '';
  
  return [quantity, unit, name].filter(Boolean).join(' ').trim();
}

export function sortMeals(meals) {
  return meals.sort((a, b) => {
    // First sort by date
    const dateComparison = new Date(a.date) - new Date(b.date);
    if (dateComparison !== 0) return dateComparison;
    
    // Then sort by meal type
    return MEAL_TYPE_ORDER[a.meal_type] - MEAL_TYPE_ORDER[b.meal_type];
  });
}
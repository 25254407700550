import * as React from "react"
import { cva, type VariantProps } from "class-variance-authority"
import { motion } from "framer-motion"
import { cn } from "src/lib/utils"

const loadingCardVariants = cva(
  "relative inline-flex bg-gray-200 rounded border border-black overflow-hidden",
  {
    variants: {
      size: {
        default: "p-2",
        sm: "p-1",
        lg: "p-3"
      },
    },
    defaultVariants: {
      size: "default",
    },
  }
)

interface LoadingCardProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof loadingCardVariants> {
  isLoading?: boolean
}

const LoadingCard = React.forwardRef<HTMLDivElement, LoadingCardProps>(
  ({ className, size, isLoading = true, children, ...props }, ref) => {
    return (
      <div className="relative" ref={ref}>
        <div
          className={cn(
            loadingCardVariants({ size, className }),
            "relative"
          )}
          {...props}
        >
          {children}
          {isLoading && (
            <div className="absolute inset-0 overflow-hidden">
              <motion.div
                className="w-1/2 h-full bg-gradient-to-r from-transparent via-green/20 to-transparent"
                animate={{
                  x: ["-100%", "200%"],
                }}
                transition={{
                  duration: 1.5,
                  repeat: Infinity,
                  ease: "easeInOut"
                }}
              />
            </div>
          )}
        </div>
      </div>
    )
  }
)

LoadingCard.displayName = "LoadingCard"

export { LoadingCard, loadingCardVariants }

import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetMealQuery } from 'src/store/apiSlice';
import RecipeItem from '../../components/RecipeItem';
import { Carousel } from 'src/components/Carousel';
import { formatDate } from 'src/utils';

export default function Meal() {
    const { meal_id } = useParams();
    const { data: meal, isLoading, isError, refetch } = useGetMealQuery(meal_id);

    if (isLoading) return <div>Loading...</div>;
    if (isError) return <div>Error loading meal data</div>;

    return (
        <div className="flex flex-col items-left p-4">
            <h1 className="text-2xl font-bold mb-4">
                {meal.meal_type.charAt(0).toUpperCase() + meal.meal_type.slice(1)} for {formatDate(meal.date)}
            </h1>
            
            <Carousel>
                {meal.recipes.map((recipeQuantity) => (
                    <RecipeItem 
                        className="mr-2"
                        key={recipeQuantity.recipe.id} 
                        recipe={recipeQuantity.recipe} 
                        quantity={recipeQuantity.multiplier}
                    />
                ))}
            </Carousel>
        </div>
    );
}
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  meal_plan_id: null,
  mealPlanStreaming: false,
  showNutrition: false,
  showParticipants: false,
  showLeftovers: true,
  selectedMealIdeaId: null,
  adjustQuantityModal: {
    isOpen: false,
    recipeId: null,
    mealId: null,
    initialMultiplier: 1,
  },
  addToMealModal: {
    isOpen: false,
    recipeId: null,
    recipeName: null,
  },
  mealNotes: {},
  sheets: {
    recipeLibrary: {
      isOpen: false,
      selectedMealId: null,
    },
    createGeneratedRecipe: {
      isOpen: false,
    },
    externalSearchRecipe: {
      isOpen: false,
    },
  },
};

const mealPlanSlice = createSlice({
  name: 'mealPlan',
  initialState,
  reducers: {
    setMealPlanId: (state, action) => {
      state.meal_plan_id = action.payload;
    },
    setMealPlanStreaming: (state, action) => {
      state.mealPlanStreaming = action.payload;
    },
    openAdjustQuantityModal: (state, action) => {
      state.adjustQuantityModal = {
        isOpen: true,
        recipeId: action.payload.recipeId,
        mealId: action.payload.mealId,
        initialMultiplier: action.payload.initialMultiplier,
      };
    },
    closeAdjustQuantityModal: (state) => {
      state.adjustQuantityModal = {
        isOpen: false,
        recipeId: null,
        mealId: null,
        initialMultiplier: 1,
      };
    },
    openAddToMealModal: (state, action) => {
      state.addToMealModal = {
        isOpen: true,
        recipeId: action.payload.recipeId,
        recipeName: action.payload.recipeName,
      };
    },
    closeAddToMealModal: (state) => {
      state.addToMealModal = {
        isOpen: false,
        recipeId: null,
        recipeName: null,
      };
    },
    adjustRecipeQuantity: (state, action) => {
      const { recipeId, multiplier } = action.payload;
      state.meals.forEach(meal => {
        const recipe = meal.recipes.find(r => r.recipe.recipe_id === recipeId);
        if (recipe) {
          recipe.multiplier = multiplier;
        }
      });
    },
    setRecipeIdeasStreaming: (state, action) => {
      state.recipeIdeasStreaming = action.payload;
    },
    toggleShowNutrition: (state) => {
      state.showNutrition = !state.showNutrition;
    },
    toggleShowParticipants: (state) => {
      state.showParticipants = !state.showParticipants;
    },
    toggleShowLeftovers: (state) => {
      state.showLeftovers = !state.showLeftovers;
    },
    setSelectedMealIdeaId: (state, action) => {
      state.selectedMealIdeaId = action.payload;
    },
    clearSelectedMealIdea: (state) => {
      state.selectedMealIdeaId = null;
    },
    initializeMealNotes: (state, action) => {
      state.mealNotes = action.payload;
    },
    updateMealNote: (state, action) => {
      const { mealId, note } = action.payload;
      state.mealNotes[mealId] = note;
    },
    setRecipeLibrarySelectedMealId: (state, action) => {
      state.sheets.recipeLibrary.selectedMealId = action.payload;
    },
    openRecipeLibrarySheet: (state) => {
      state.sheets.recipeLibrary.isOpen = true;
    },
    closeRecipeLibrarySheet: (state) => {
      state.sheets.recipeLibrary.isOpen = false;
    },
    openCreateGeneratedRecipeSheet: (state) => {
      state.sheets.createGeneratedRecipe.isOpen = true;
    },
    closeCreateGeneratedRecipeSheet: (state) => {
      state.sheets.createGeneratedRecipe.isOpen = false;
    },
    openExternalSearchRecipeSheet: (state) => {
      state.sheets.externalSearchRecipe.isOpen = true;
    },
    closeExternalSearchRecipeSheet: (state) => {
      state.sheets.externalSearchRecipe.isOpen = false;
    },
  }
});

export const {
  setMealPlanId,
  setMealPlanStreaming,
  openAdjustQuantityModal,
  closeAdjustQuantityModal,
  openAddToMealModal,
  closeAddToMealModal,
  adjustRecipeQuantity,
  setRecipeIdeasStreaming,
  toggleShowNutrition,
  toggleShowParticipants,
  toggleShowLeftovers,
  setSelectedMealIdeaId,
  clearSelectedMealIdea,
  initializeMealNotes,
  updateMealNote,
  setRecipeLibrarySelectedMealId,
  openRecipeLibrarySheet,
  closeRecipeLibrarySheet,
  openCreateGeneratedRecipeSheet,
  closeCreateGeneratedRecipeSheet,
  openExternalSearchRecipeSheet,
  closeExternalSearchRecipeSheet,
} = mealPlanSlice.actions;

export default mealPlanSlice.reducer;

import React, { useState, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../components/Button';
import { Input } from '../../components/Input';
import { useCreateRecipeMutation } from '../../store/apiSlice';
import { setGeneratedRecipeId, setRecipeChatStreaming } from './recipeGenerationSlice';
import WebSocketGenerationService from '../../services/WebSocketGenerationService';
import { apiSlice } from '../../store/apiSlice';

const RecipeGenerationChat = () => {
    const [userQuery, setUserQuery] = useState('');
    const [createRecipe, { isLoading }] = useCreateRecipeMutation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const generatedRecipeId = useSelector((state) => state.recipeGeneration.generatedRecipeId);
    const recipeChatStreaming = useSelector((state) => state.recipeGeneration.recipeChatStreaming);
    const wsService = useRef(null);

    const initiateWebSocketConnection = useCallback((query, recipeId) => {
        if (wsService.current) {
            wsService.current.disconnect();
            wsService.current = null;
        }

        wsService.current = new WebSocketGenerationService({
            endpoint: 'stream_recipe_generation',
            payload: {
                user_query: query,
                generated_recipe_id: recipeId,
            },
            onOpen: () => {
                dispatch(setRecipeChatStreaming(true));
            },
            onMessage: (data) => {
                // Optimistically update the recipe data
                dispatch(
                    apiSlice.util.updateQueryData(
                        'getGeneratedRecipe',
                        recipeId,
                        () => data
                    )
                );
            },
            onError: (error) => {
                console.error('WebSocket error:', error);
                dispatch(setRecipeChatStreaming(false));
            },
            onClose: () => {
                console.log('WebSocket is closed');
                wsService.current = null;
                dispatch(setRecipeChatStreaming(false));
            }
        });

        wsService.current.connect();
    }, [dispatch]);

    const handleSubmit = useCallback(async () => {
        if (!userQuery.trim() || isLoading) return;

        try {
            if (!generatedRecipeId) {
                // If no recipe exists, create a new one
                const newRecipeId = await createRecipe().unwrap();
                dispatch(setGeneratedRecipeId(newRecipeId));
                navigate(`/create_recipe/${newRecipeId}`, { replace: true });
                initiateWebSocketConnection(userQuery.trim(), newRecipeId);
            } else {
                // If recipe exists, update it
                initiateWebSocketConnection(userQuery.trim(), generatedRecipeId);
            }
            
            setUserQuery('');
        } catch (error) {
            console.error('Failed to handle recipe:', error);
            // TODO: Add error handling
        }
    }, [userQuery, isLoading, createRecipe, dispatch, navigate, generatedRecipeId, initiateWebSocketConnection]);

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSubmit();
        }
    };

    return (
        <div className="py-2 px-4 max-w-7xl">
            <div className="flex space-x-2">
                <Input
                    className="flex-grow"
                    placeholder="Describe your recipe idea..."
                    value={userQuery}
                    disabled={recipeChatStreaming || isLoading}
                    onChange={(e) => setUserQuery(e.target.value)}
                    onKeyPress={handleKeyPress}
                />
                <Button
                    onClick={handleSubmit}
                    disabled={recipeChatStreaming || isLoading}
                    variant="reverse"
                >
                    {userQuery.trim() ? "Generate Recipe" : "Start Fresh"}
                </Button>
            </div>
        </div>
    );
};

export default RecipeGenerationChat; 
import React, { useState, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useGetMealPlanQuery, useListMealsQuery, apiSlice } from '../../store/apiSlice';
import { setMealPlanStreaming, clearSelectedMealIdea } from './mealPlanSlice';
import { Button } from '../../components/Button';
import { Input } from '../../components/Input';
import WebSocketGenerationService from '../../services/WebSocketGenerationService';

const MealPlanChat = () => {
    const dispatch = useDispatch();
    const meal_plan_id = useSelector(state => state.mealPlan.meal_plan_id);
    const mealPlanStreaming = useSelector(state => state.mealPlan.mealPlanStreaming);
    const { data: mealPlan, isLoading, error, refetch } = useGetMealPlanQuery(meal_plan_id);
    const { data: mealsData } = useListMealsQuery({ mealPlanId: meal_plan_id });
    const [feedbackValue, setFeedbackValue] = useState('');
    const wsService = useRef(null);
    const mealNotes = useSelector(state => state.mealPlan.mealNotes);

    const initiateWebSocketConnection = useCallback((userFeedback) => {
        if (wsService.current) {
            wsService.current.disconnect();
            wsService.current = null;
        }

        wsService.current = new WebSocketGenerationService({
            endpoint: 'generate_meal_ideas',
            payload: {
                meal_plan_id: meal_plan_id,
                user_feedback: userFeedback,
                meal_notes: mealNotes,
                target_meal_ids: mealsData?.meals?.map(meal => meal.id) || [],
            },
            onOpen: () => {
                dispatch(setMealPlanStreaming(true));
                dispatch(clearSelectedMealIdea());
            },
            onMessage: (data) => {
                // Optimistically update just the meal_ideas in the meal plan data
                dispatch(
                    apiSlice.util.updateQueryData(
                        'getMealPlan',
                        meal_plan_id,
                        (draft) => {
                            draft.meal_ideas = data.meal_ideas;
                        }
                    )
                );
            },
            onError: (error) => {
                console.error('WebSocket error:', error);
                dispatch(setMealPlanStreaming(false));
            },
            onClose: () => {
                console.log('WebSocket is closed');
                wsService.current = null;
                dispatch(setMealPlanStreaming(false));
            }
        });

        wsService.current.connect();
    }, [meal_plan_id, dispatch, refetch, mealNotes, mealsData]);

    const handleFeedbackSubmit = useCallback(() => {
        initiateWebSocketConnection(feedbackValue.trim());
        setFeedbackValue('');
    }, [feedbackValue, initiateWebSocketConnection]);

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            handleFeedbackSubmit();
        }
    };

    if (isLoading) {
        return <div>Loading meal plan...</div>;
    }

    if (error) {
        return <div>Error loading meal plan: {error.message}</div>;
    }

    return (
        <div className="pb-2 px-2 max-w-7xl">
            <div className="flex space-x-2">
                <Input
                    className="flex-grow"
                    placeholder="What do you want to cook?"
                    value={feedbackValue}
                    disabled={mealPlanStreaming}
                    onChange={(e) => setFeedbackValue(e.target.value)}
                    onKeyPress={handleKeyPress}
                />
                <Button
                    onClick={handleFeedbackSubmit}
                    disabled={mealPlanStreaming}
                    variant="reverse"
                >
                    {feedbackValue.trim() ? "Get ideas" : "Surprise me!"}
                </Button>
            </div>
        </div>
    );
};

export default MealPlanChat;

import React from 'react';
import { SheetHeader, SheetTitle } from '../../components/Sheet';

const CreateGeneratedRecipeSheetContent = () => {
  return (
    <>
      <SheetHeader>
        <SheetTitle>Create Recipe</SheetTitle>
      </SheetHeader>
      <div className="py-4">
        Content goes here
      </div>
    </>
  );
};

export default CreateGeneratedRecipeSheetContent; 
import { Globe, ChefHat, Library } from 'lucide-react';
import { LoadingCard } from '../../components/LoadingCard';

const UnhydratedRecipeIdea = ({ idea }) => {
    let Icon, loadingText;
    
    switch (idea.function) {
        case 'find_internet_recipe':
            Icon = Globe;
            loadingText = `Searching internet for ${idea.value}`;
            break;
        case 'build_simple_ai_recipe':
            Icon = ChefHat;
            loadingText = `Building recipe ${idea.value}`;
            break;
        case 'use_recipe_from_library':
            Icon = Library;
            loadingText = `Getting recipe from library`;
            break;
        default:
            Icon = Globe;
            loadingText = 'Loading recipe...';
    }

    return (
        <LoadingCard className="mr-2">
            <div className="flex items-center gap-2">
                <Icon className="h-4 w-4" />
                <span className="text-sm">{loadingText}</span>
            </div>
        </LoadingCard>
    );
};

export default UnhydratedRecipeIdea; 
import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Button } from '../../components/Button';

const Animations = () => {
  const [isAnimating, setIsAnimating] = useState(false);
  const [isTextScaled, setIsTextScaled] = useState(false);

  return (
    <div className="p-8 space-y-12">
      <div className="space-y-4">
        <h2 className="text-2xl font-bold">Simple Slide Animation</h2>
        <motion.div
          animate={{
            x: isAnimating ? 200 : 0,
          }}
          transition={{ duration: 1 }}
          className="w-32 h-32 bg-blue rounded-lg"
        />
        <Button 
          variant="default"
          onClick={() => setIsAnimating(!isAnimating)}
        >
          {isAnimating ? "Slide Back" : "Slide Right"}
        </Button>
      </div>

      <div className="space-y-4">
        <h2 className="text-2xl font-bold">Text Scale Animation</h2>
        <motion.div layout className="bg-gray-400 rounded-lg inline-block">
          <motion.div
            layout
            className={`
              ${isTextScaled ? 'text-md p-2' : 'text-4xl p-4'}
            `}
          >
            Scaling Text
          </motion.div>
        </motion.div>
        <div>
          <Button 
            variant="default"
            onClick={() => setIsTextScaled(!isTextScaled)}
          >
            {isTextScaled ? "Scale Up" : "Scale Down"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Animations;

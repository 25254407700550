import React, { useEffect, useContext } from 'react';
import MealPlanMealList from './MealPlanMealList';
import MealPlanChat from './MealPlanChat';
import { useParams } from 'react-router-dom';
import { useGetMealPlanQuery, useListMealsQuery } from '../../store/apiSlice';
import { useDispatch, useSelector } from 'react-redux';
import { setMealPlanId } from './mealPlanSlice';
import NavbarContext from '../../NavBarContext';
import FooterContext from '../../FooterContext';
import MealPlanControlPanel from './MealPlanControlPanel';
import { Sheet, SheetContent } from '../../components/Sheet';
import RecipeLibrarySheetContent from './RecipeLibrarySheetContent';
import { closeRecipeLibrarySheet } from './mealPlanSlice';
import CreateGeneratedRecipeSheetContent from './CreateGeneratedRecipeSheetContent';
import ExternalSearchRecipeSheetContent from './ExternalSearchRecipeSheetContent';
import { 
    closeCreateGeneratedRecipeSheet,
    closeExternalSearchRecipeSheet 
} from './mealPlanSlice';

const MealPlan = () => {
    const { meal_plan_id } = useParams();
    const dispatch = useDispatch();
    const { data: mealPlan, error: mealPlanError, isLoading: mealPlanLoading } = useGetMealPlanQuery(meal_plan_id);
    const { data, error: mealsError, isLoading: mealsLoading } = useListMealsQuery({ mealPlanId: meal_plan_id });
    const recipeLibraryOpen = useSelector(state => state.mealPlan.sheets.recipeLibrary.isOpen);
    const createGeneratedRecipeOpen = useSelector(state => state.mealPlan.sheets.createGeneratedRecipe.isOpen);
    const externalSearchRecipeOpen = useSelector(state => state.mealPlan.sheets.externalSearchRecipe.isOpen);

    const { setShowNavbar } = useContext(NavbarContext);
    const { setShowFooter } = useContext(FooterContext);

    useEffect(() => {
        if (meal_plan_id) {
            dispatch(setMealPlanId(meal_plan_id));
        }
        // Show navbar, hide footer
        setShowNavbar(true);
        setShowFooter(false);

        // Cleanup function to show footer when component unmounts
        return () => {
            setShowFooter(true);
        };
    }, [meal_plan_id, dispatch, setShowNavbar, setShowFooter]);

    if (mealPlanLoading || mealsLoading) {
        return <div><span className="loading loading-dots loading-lg"></span></div>;
    }

    if (mealPlanError || mealsError) {
        return <div>Error loading meal plan: {mealPlanError?.message || mealsError?.message}</div>;
    }

    return (
        <div className="flex flex-col h-[calc(100vh-64px)]">
            <div className="flex flex-col flex-grow overflow-hidden">
                {/* Main content area */}
                <div className="flex-grow overflow-y-auto">
                    <div className="flex flex-col items-center w-full">
                        <div className="w-full max-w-7xl">
                            <MealPlanMealList />
                        </div>
                    </div>
                </div>
                {/* Control panel and chat pinned to bottom */}
                <div className="flex-shrink-0 border-t border-black rounded-t-base w-full">
                    <div className="flex flex-col items-center w-full">
                        <div className="w-full max-w-7xl">
                            <MealPlanControlPanel />
                            <MealPlanChat />
                        </div>
                    </div>
                </div>
            </div>
            <Sheet open={recipeLibraryOpen} onOpenChange={() => dispatch(closeRecipeLibrarySheet())}>
                <SheetContent side="bottom" className="max-h-[65vh] overflow-y-auto">
                    <RecipeLibrarySheetContent />
                </SheetContent>
            </Sheet>

            <Sheet open={createGeneratedRecipeOpen} onOpenChange={() => dispatch(closeCreateGeneratedRecipeSheet())}>
                <SheetContent side="bottom" className="max-h-[65vh] overflow-y-auto">
                    <CreateGeneratedRecipeSheetContent />
                </SheetContent>
            </Sheet>

            <Sheet open={externalSearchRecipeOpen} onOpenChange={() => dispatch(closeExternalSearchRecipeSheet())}>
                <SheetContent side="bottom" className="max-h-[65vh] overflow-y-auto">
                    <ExternalSearchRecipeSheetContent />
                </SheetContent>
            </Sheet>
        </div>
    );
};

export default MealPlan;

import React from 'react';

const RecipeGenerationChatUser = ({ message }) => {
    return (
        <div className="flex justify-end">
            <div className="max-w-md bg-white border border-black rounded-lg py-1 px-3 whitespace-pre-wrap">
                {message.content}
            </div>
        </div>
    );
};

export default RecipeGenerationChatUser;

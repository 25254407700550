import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useGetMealPlanQuery, useClearMealPlanHistoryMutation } from '../../store/apiSlice';
import { Button } from '../../components/Button';
import { SmallButtonGroup, SmallGroupToggle, SmallGroupButton } from '../../components/SmallButtonGroup';
import { Table2, Users, Microwave, X, Plus } from 'lucide-react';
import {
    DropdownMenu,
    DropdownMenuTrigger,
    DropdownMenuContent,
    DropdownMenuItem,
} from '../../components/DropdownMenu';
import { toggleShowNutrition, toggleShowParticipants, toggleShowLeftovers, openRecipeLibrarySheet, openCreateGeneratedRecipeSheet, openExternalSearchRecipeSheet } from './mealPlanSlice';
import MealPlanRecipeLibrary from './MealPlanRecipeLibrary';

const MealPlanControlPanel = () => {
    const dispatch = useDispatch();
    const meal_plan_id = useSelector(state => state.mealPlan.meal_plan_id);
    const showNutrition = useSelector(state => state.mealPlan.showNutrition);
    const showParticipants = useSelector(state => state.mealPlan.showParticipants);
    const showLeftovers = useSelector(state => state.mealPlan.showLeftovers);
    const mealPlanStreaming = useSelector(state => state.mealPlan.mealPlanStreaming);
    const { data: mealPlan, refetch } = useGetMealPlanQuery(meal_plan_id);
    const [clearMealPlanHistory] = useClearMealPlanHistoryMutation();
    const [showHistory, setShowHistory] = React.useState(false);
    const [dropdownOpen, setDropdownOpen] = React.useState(false);

    
    const activeToggles = [
        ...(showNutrition ? ['table'] : []),
        ...(showParticipants ? ['users'] : []),
        ...(showLeftovers ? ['microwave'] : []),
    ];

    const handleTogglesChange = useCallback((newToggles) => {
        if (newToggles.includes('table') !== showNutrition) {
            dispatch(toggleShowNutrition());
        }
        if (newToggles.includes('users') !== showParticipants) {
            dispatch(toggleShowParticipants());
        }
        if (newToggles.includes('microwave') !== showLeftovers) {
            dispatch(toggleShowLeftovers());
        }
    }, [dispatch, showNutrition, showParticipants, showLeftovers]);

    const handleClearHistory = useCallback(async () => {
        try {
            await clearMealPlanHistory(meal_plan_id);
            refetch();
        } catch (error) {
            console.error('Error clearing meal plan history:', error);
        }
    }, [clearMealPlanHistory, meal_plan_id, refetch]);

    const toggleHistoryDisplay = () => {
        setShowHistory(!showHistory);
    };

    const handleRecipeSelect = (recipe) => {
        // TODO: Implement adding recipe to meal plan
        console.log('Selected recipe:', recipe);
    };

    return (
        <div className="p-2 max-w-7xl">
            <div className="flex items-center justify-between">
                {/* Left side */}
                <div className="flex items-center">
                    <SmallButtonGroup 
                        type="multiple" 
                        value={activeToggles}
                        disabled={mealPlanStreaming}
                        onValueChange={handleTogglesChange}
                    >
                        <SmallGroupToggle value="table" aria-label="Toggle table view">
                            <Table2 className="h-4 w-4" />
                        </SmallGroupToggle>
                        <SmallGroupToggle value="users" aria-label="Toggle users view">
                            <Users className="h-4 w-4" />
                        </SmallGroupToggle>
                        <SmallGroupToggle value="microwave" aria-label="Toggle leftovers view">
                            <Microwave className="h-4 w-4" />
                        </SmallGroupToggle>
                        <SmallGroupButton onClick={handleClearHistory} aria-label="Clear history">
                            <X className="h-4 w-4" />
                        </SmallGroupButton>
                    </SmallButtonGroup>
                </div>

                {/* Right side */}
                <div className="flex items-center space-x-2">
                    <Button variant="link" size="sm" disabled={mealPlanStreaming}>
                        Using 3 notes
                    </Button>
                    <Button variant="neutralNoShadow" size="sm" disabled={mealPlanStreaming}>
                        Settings
                    </Button>
                    <DropdownMenu open={dropdownOpen} onOpenChange={setDropdownOpen}>
                        <DropdownMenuTrigger asChild>
                            <Button 
                                variant="noShadow" 
                                size="smIcon" 
                                aria-label="Add recipe" 
                                disabled={mealPlanStreaming}
                            >
                                <Plus className="h-4 w-4" />
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent>
                            <DropdownMenuItem 
                                onSelect={() => {
                                    setDropdownOpen(false);
                                    dispatch(openRecipeLibrarySheet());
                                }}
                            >
                                Add from library
                            </DropdownMenuItem>
                            <DropdownMenuItem 
                                onSelect={() => {
                                    setDropdownOpen(false);
                                    dispatch(openCreateGeneratedRecipeSheet());
                                }}
                            >
                                Create recipe
                            </DropdownMenuItem>
                            <DropdownMenuItem 
                                onSelect={() => {
                                    setDropdownOpen(false);
                                    dispatch(openExternalSearchRecipeSheet());
                                }}
                            >
                                Search for recipe
                            </DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                </div>
            </div>
        </div>
    );
};

export default MealPlanControlPanel;

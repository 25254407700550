import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Carousel } from '../../components/Carousel';
import UnhydratedRecipeIdea from './UnhydratedRecipeIdea';
import HydratedRecipeIdea from './HydratedRecipeIdea';
import { setMealPlanStreaming } from './mealPlanSlice';
import WebSocketGenerationService from '../../services/WebSocketGenerationService';
import { apiSlice, useGetMealPlanQuery } from '../../store/apiSlice';

const MealIdea = ({ mealId }) => {
    const selectedMealIdeaId = useSelector(state => state.mealPlan.selectedMealIdeaId);
    const meal_plan_id = useSelector(state => state.mealPlan.meal_plan_id);
    const dispatch = useDispatch();
    const wsService = useRef(null);

    const { data: mealPlan } = useGetMealPlanQuery(meal_plan_id);
    
    const currentMealIdea = selectedMealIdeaId 
        ? mealPlan?.meal_ideas?.find(idea => 
            idea.id === selectedMealIdeaId && 
            idea.meal_id === mealId
        ) : null;

    useEffect(() => {
        if (!meal_plan_id || !selectedMealIdeaId || !currentMealIdea?.hydrated === false) return;

        wsService.current = new WebSocketGenerationService({
            endpoint: `hydrate_meal_idea`,
            payload: {
                meal_plan_id,
                meal_idea_id: selectedMealIdeaId,
            },
            onOpen: () => dispatch(setMealPlanStreaming(true)),
            onMessage: (data) => {
                dispatch(
                    apiSlice.util.updateQueryData(
                        'getMealPlan',
                        meal_plan_id,
                        (draft) => {
                            const ideaIndex = draft.meal_ideas.findIndex(
                                idea => idea.id === selectedMealIdeaId
                            );
                            if (ideaIndex !== -1) {
                                draft.meal_ideas[ideaIndex] = data;
                            }
                        }
                    )
                );
            },
            onError: () => dispatch(setMealPlanStreaming(false)),
            onClose: () => dispatch(setMealPlanStreaming(false))
        });

        wsService.current.connect();

        return () => {
            if (wsService.current) {
                wsService.current.disconnect();
                wsService.current = null;
            }
        };
    }, [meal_plan_id, selectedMealIdeaId, currentMealIdea?.hydrated, dispatch]);

    if (!currentMealIdea) return null;

    return (
        <div>
            <Carousel>
                {currentMealIdea.recipe_ideas?.map((idea) => (
                    <React.Fragment key={idea.id}>
                        {!idea.recipe ? (
                            <UnhydratedRecipeIdea idea={idea} />
                        ) : (
                            <HydratedRecipeIdea 
                                idea={idea} 
                                mealId={mealId}
                            />
                        )}
                    </React.Fragment>
                ))}
            </Carousel>
        </div>
    );
};

export default MealIdea;

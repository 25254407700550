import React from 'react';
import { useSelector } from 'react-redux';
import RecipeGenerationChatUser from './RecipeGenerationChatUser';
import RecipeGenerationChatAssistant from './RecipeGenerationChatAssistant';
import { useGetGeneratedRecipeQuery } from '../../store/apiSlice';

const RecipeGenerationResponse = () => {
    const generatedRecipeId = useSelector((state) => state.recipeGeneration.generatedRecipeId);
    const { data, error, isLoading } = useGetGeneratedRecipeQuery(generatedRecipeId, {
        skip: !generatedRecipeId, // Skip the query if no ID is available
    });

    if (error) return <div>Error loading recipe messages.</div>;
    if (isLoading ||!data || !data?.messages || !generatedRecipeId) return null;

    const messages = data?.messages || [];

    return (
        <div className="p-4">
            <div className="flex flex-col space-y-6">
                {messages.map((message, index) => (
                    message.role === 'user' ? (
                        <RecipeGenerationChatUser key={index} message={message} />
                    ) : (
                        <RecipeGenerationChatAssistant key={index} message={message} />
                    )
                ))}
            </div>
        </div>
    );
};

export default RecipeGenerationResponse; 
import React, { useState, useRef } from 'react';
import FocusedTextInput from '../../components/FocusedTextInput';
import { testRecipeExternal, testRecipeInternal } from '../../testData'; // Import testRecipeExternal as a named export
import RecipeItem from '../../components/RecipeItem';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, FreeMode } from 'swiper/modules';
import { ToggleableCard } from '../../components/ToggleableCard';
import ColoredButton from '../../components/ColoredButton';
import { Badge } from '../../components/Badge';
import { Button } from '../../components/Button';
import { Input } from '../../components/Input';
import { TextArea } from '../../components/TextArea';
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogDescription,
} from '../../components/Dialog';
import { Checkbox } from '../../components/Checkbox';
import { Select, SelectGroup, SelectValue, SelectTrigger, SelectContent, SelectLabel, SelectItem, SelectSeparator } from '../../components/Select';
import { Label } from '../../components/Label';
import StickyNote from '../../components/StickyNote';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '../../components/Tabs';
import { Accordion, AccordionItem, AccordionTrigger, AccordionContent } from '../../components/Accordion';
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem } from '../../components/DropdownMenu';
import { MultiActionButton } from '../../components/MultiActionButton';
import NutritionBar from '../../components/NutritionBar';
import { Switch } from '../../components/Switch';
import { MoreHorizontal } from 'lucide-react';
import {
  Sheet,
  SheetTrigger,
  SheetContent,
  SheetHeader,
  SheetFooter,
  SheetTitle,
  SheetDescription,
} from '../../components/Sheet';
import { SmallButtonGroup, SmallGroupToggle, SmallGroupButton } from '../../components/SmallButtonGroup';
import { Bold, Italic, Underline, AlignLeft, AlignCenter, AlignRight, Moon, Sun, Laptop } from "lucide-react"
import { Carousel } from '../../components/Carousel';
import { LoadingCard } from '../../components/LoadingCard'
import { Loader2 } from 'lucide-react'
import {
  Drawer,
  DrawerTrigger,
  DrawerContent,
  DrawerHeader,
  DrawerFooter,
  DrawerTitle,
  DrawerDescription,
} from '../../components/Drawer'
import { RadioGroup, RadioGroupItem } from '../../components/Radio';

const Styling = () => {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocusChange = (focused) => {
      setIsFocused(focused);
  };

  const handleCardToggle = (isToggled) => {
    console.log('Card toggled:', isToggled);
    // You can add any additional logic here
  };

  const multiActions = [
    { label: "Primary Action", onClick: () => alert("Primary Action clicked") },
    { label: "Secondary Action", onClick: () => alert("Secondary Action clicked") },
    { label: "Tertiary Action", onClick: () => alert("Tertiary Action clicked") },
  ];

  return (
    <div>
      <div className="mb-8">
        <h2 className="text-xl font-bold mb-4">Components</h2>
        
        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-2">Standard Components</h3>
          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2">Badges</h3>
            <div className="flex space-x-4">
              <Badge variant="default">Default Badge</Badge>
              <Badge variant="secondary">Secondary Badge</Badge>
              <Badge variant="destructive">Destructive Badge</Badge>
              <Badge variant="outline">Outline Badge</Badge>
            </div>
          </div>
          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2">Button Variants</h3>
            <div className="flex space-x-4">
              <Button variant="default" size="default">Default Button</Button>
              <Button variant="noShadow" size="default">No Shadow Button</Button>
              <Button variant="dark" size="default">Dark Button</Button>
              <Button variant="link" size="default">Link Button</Button>
              <Button variant="neutral" size="default">Neutral Button</Button>
              <Button variant="reverse" size="default">Reverse Button</Button>
            </div>
          </div>
          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2">Button Sizes</h3>
            <div className="flex space-x-4">
              <Button variant="default" size="sm">Small Default Button</Button>
              <Button variant="default" size="lg">Large Default Button</Button>
              <Button variant="default" size="icon">TODO: Add Icon</Button>
            </div>
          </div>

          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2">Select Variants</h3>
            <div className="flex space-x-4">
              <Select>
                <SelectTrigger className="w-48">
                  <SelectValue placeholder="Default Select" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectLabel>Produce</SelectLabel>
                    <SelectItem value="fruits">Fruits</SelectItem>
                    <SelectItem value="vegetables">Vegetables</SelectItem>
                  </SelectGroup>
                  <SelectSeparator />
                  <SelectGroup>
                    <SelectLabel>Butcher</SelectLabel>
                    <SelectItem value="beef">Beef</SelectItem>
                    <SelectItem value="chicken">Chicken</SelectItem>
                    <SelectItem value="pork">Pork</SelectItem>
                  </SelectGroup>
                  <SelectSeparator />
                  <SelectGroup>
                    <SelectLabel>Frozen Foods</SelectLabel>
                    <SelectItem value="icecream">Ice Cream</SelectItem>
                    <SelectItem value="frozenmeals">Frozen Meals</SelectItem>
                  </SelectGroup>
                  <SelectSeparator />
                  <SelectGroup>
                    <SelectLabel>Dairy</SelectLabel>
                    <SelectItem value="milk">Milk</SelectItem>
                    <SelectItem value="cheese">Cheese</SelectItem>
                    <SelectItem value="yogurt">Yogurt</SelectItem>
                  </SelectGroup>
                  <SelectSeparator />
                  <SelectGroup>
                    <SelectLabel>Bakery</SelectLabel>
                    <SelectItem value="bread">Bread</SelectItem>
                    <SelectItem value="pastries">Pastries</SelectItem>
                  </SelectGroup>
                  <SelectSeparator />
                  <SelectGroup>
                    <SelectLabel>Ethnic</SelectLabel>
                    <SelectItem value="asian">Asian</SelectItem>
                    <SelectItem value="mexican">Mexican</SelectItem>
                    <SelectItem value="indian">Indian</SelectItem>
                  </SelectGroup>
                  <SelectSeparator />
                  <SelectGroup>
                    <SelectLabel>Other</SelectLabel>
                    <SelectItem value="snacks">Snacks</SelectItem>
                    <SelectItem value="beverages">Beverages</SelectItem>
                    <SelectItem value="cleaning">Cleaning Supplies</SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>

              <Select>
                <SelectTrigger className="w-48" variant="neutral">
                  <SelectValue placeholder="Neutral Select" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectLabel>Produce</SelectLabel>
                    <SelectItem value="fruits">Fruits</SelectItem>
                    <SelectItem value="vegetables">Vegetables</SelectItem>
                  </SelectGroup>
                  <SelectSeparator />
                  <SelectGroup>
                    <SelectLabel>Dairy</SelectLabel>
                    <SelectItem value="milk">Milk</SelectItem>
                    <SelectItem value="cheese">Cheese</SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
          </div>
          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2">Checkbox</h3>
            <div className="flex space-x-4">
              <Checkbox />
            </div>
            <div className="flex space-x-4">
              <Label>Label</Label>
              <Checkbox />
            </div>
          </div>
          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2">Inputs</h3>
            <div className="flex space-x-4">
              <Input type="text" placeholder="Default Input" />
              <Input type="text" placeholder="Disabled Input" disabled />
              <Input type="text" placeholder="Clean Input" variant="clean" />
            </div>
          </div>
          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2">Focused Text Input</h3>
            <div className="flex space-x-4">
              <FocusedTextInput placeholder="Focused Text Input" onFocusChange={(focused) => console.log('Focused:', focused)} />
            </div>
          </div>
          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2">Text Area</h3>
            <div className="flex space-x-4">
              <TextArea placeholder="Enter text here..." className="w-full" />
            </div>
          </div>
          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2">Dialog</h3>
            <Dialog>
              <DialogTrigger asChild>
                <Button variant="default">Open Normal Dialog</Button>
              </DialogTrigger>
              <DialogContent variant="default">
                <DialogHeader>
                  <DialogTitle>Normal Dialog Title</DialogTitle>
                  <DialogDescription>
                    This is a description of the normal dialog content.
                  </DialogDescription>
                </DialogHeader>
                <p>This is the main content of the normal dialog. You can add any components or text here.</p>
                <DialogFooter>
                  <Button variant="default">Action</Button>
                </DialogFooter>
              </DialogContent>
            </Dialog>
            <Dialog>
              <DialogTrigger asChild>
                <Button variant="default">Open Wide Dialog</Button>
              </DialogTrigger>
              <DialogContent variant="wide">
                <DialogHeader>
                  <DialogTitle>Wide Dialog Title</DialogTitle>
                  <DialogDescription>
                    This is a description of the wide dialog content.
                  </DialogDescription>
                </DialogHeader>
                <p>This is the main content of the wide dialog. You can add any components or text here.</p>
                <DialogFooter>
                  <Button variant="default">Action</Button>
                </DialogFooter>
              </DialogContent>
            </Dialog>
          </div>
        </div>
        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-2">Tabs</h3>
          <Tabs defaultValue="tab1">
            <TabsList>
              <TabsTrigger value="tab1">Tab 1</TabsTrigger>
              <TabsTrigger value="tab2">Tab 2</TabsTrigger>
              <TabsTrigger value="tab3">Tab 3</TabsTrigger>
            </TabsList>
            <TabsContent value="tab1">
              <p>This is the content of Tab 1. You can add any components or text here.</p>
            </TabsContent>
            <TabsContent value="tab2">
              <p>This is the content of Tab 2. You can add any components or text here.</p>
            </TabsContent>
            <TabsContent value="tab3">
              <p>This is the content of Tab 3. You can add any components or text here.</p>
            </TabsContent>
          </Tabs>
        </div>
        <div>
          <h3 className="text-lg font-semibold mb-2">Custom Components</h3>
          <div className="mb-4 space-y-4">
            {/* External Recipe Items Carousel */}
            <div className="mb-8">
              <h4 className="text-md font-medium mb-2">External Recipe Items</h4>
              <Carousel>
                <RecipeItem 
                  recipe={testRecipeExternal}
                  actions={[
                    { actionText: "Action 1", actionHandler: () => alert("Action 1 clicked") },
                    { actionText: "Action 2", actionHandler: () => alert("Action 2 clicked") }
                  ]}
                />
                <RecipeItem 
                  recipe={{...testRecipeExternal, nutrition: undefined}}
                  actions={[
                    { actionText: "Action 1", actionHandler: () => alert("Action 1 clicked") },
                    { actionText: "Action 2", actionHandler: () => alert("Action 2 clicked") }
                  ]}
                />
                <RecipeItem 
                  recipe={{...testRecipeExternal, rating_value: undefined, rating_count: undefined}}
                  actions={[
                    { actionText: "Action 1", actionHandler: () => alert("Action 1 clicked") },
                    { actionText: "Action 2", actionHandler: () => alert("Action 2 clicked") }
                  ]}
                />
                <RecipeItem 
                  recipe={{...testRecipeExternal, parsed_ingredients: undefined, rating_value: undefined, rating_count: undefined, total_time: undefined}}
                  actions={[
                    { actionText: "Action 1", actionHandler: () => alert("Action 1 clicked") },
                    { actionText: "Action 2", actionHandler: () => alert("Action 2 clicked") }
                  ]}
                />
                <RecipeItem 
                  recipe={{...testRecipeExternal, image: undefined}}
                  actions={[
                    { actionText: "Action 1", actionHandler: () => alert("Action 1 clicked") },
                    { actionText: "Action 2", actionHandler: () => alert("Action 2 clicked") }
                  ]}
                />
                <RecipeItem 
                  recipe={{...testRecipeExternal, nutrition: undefined, image: undefined}}
                  actions={[
                    { actionText: "Action 1", actionHandler: () => alert("Action 1 clicked") },
                    { actionText: "Action 2", actionHandler: () => alert("Action 2 clicked") }
                  ]}
                />
                <RecipeItem 
                  recipe={{...testRecipeExternal, rating_value: undefined, rating_count: undefined, image: undefined}}
                  actions={[
                    { actionText: "Action 1", actionHandler: () => alert("Action 1 clicked") },
                    { actionText: "Action 2", actionHandler: () => alert("Action 2 clicked") }
                  ]}
                />
                <RecipeItem 
                  recipe={{...testRecipeExternal, parsed_ingredients: undefined, rating_value: undefined, rating_count: undefined, total_time: undefined, image: undefined}}
                  actions={[
                    { actionText: "Action 1", actionHandler: () => alert("Action 1 clicked") },
                    { actionText: "Action 2", actionHandler: () => alert("Action 2 clicked") }
                  ]}
                />
              </Carousel>
            </div>

            {/* Internal Recipe Items Carousel */}
            <div className="mb-8">
              <h4 className="text-md font-medium mb-2">Internal Recipe Items</h4>
              <Carousel>
                <RecipeItem 
                  recipe={testRecipeInternal}
                  actions={[
                    { actionText: "Action 1", actionHandler: () => alert("Action 1 clicked") },
                    { actionText: "Action 2", actionHandler: () => alert("Action 2 clicked") }
                  ]}
                />
                <RecipeItem 
                  recipe={{...testRecipeInternal, nutrition: undefined}}
                  actions={[
                    { actionText: "Action 1", actionHandler: () => alert("Action 1 clicked") },
                    { actionText: "Action 2", actionHandler: () => alert("Action 2 clicked") }
                  ]}
                />
                <RecipeItem 
                  recipe={{...testRecipeInternal, rating_value: undefined, rating_count: undefined}}
                  actions={[
                    { actionText: "Action 1", actionHandler: () => alert("Action 1 clicked") },
                    { actionText: "Action 2", actionHandler: () => alert("Action 2 clicked") }
                  ]}
                />
                <RecipeItem 
                  recipe={{...testRecipeInternal, parsed_ingredients: undefined, rating_value: undefined, rating_count: undefined, total_time: undefined}}
                  actions={[
                    { actionText: "Action 1", actionHandler: () => alert("Action 1 clicked") },
                    { actionText: "Action 2", actionHandler: () => alert("Action 2 clicked") }
                  ]}
                />
                <RecipeItem 
                  recipe={{...testRecipeInternal, image: undefined}}
                  actions={[
                    { actionText: "Action 1", actionHandler: () => alert("Action 1 clicked") },
                    { actionText: "Action 2", actionHandler: () => alert("Action 2 clicked") }
                  ]}
                />
                <RecipeItem 
                  recipe={{...testRecipeInternal, nutrition: undefined, image: undefined}}
                  actions={[
                    { actionText: "Action 1", actionHandler: () => alert("Action 1 clicked") },
                    { actionText: "Action 2", actionHandler: () => alert("Action 2 clicked") }
                  ]}
                />
                <RecipeItem 
                  recipe={{...testRecipeInternal, rating_value: undefined, rating_count: undefined, image: undefined}}
                  actions={[
                    { actionText: "Action 1", actionHandler: () => alert("Action 1 clicked") },
                    { actionText: "Action 2", actionHandler: () => alert("Action 2 clicked") }
                  ]}
                />
                <RecipeItem 
                  recipe={{...testRecipeInternal, parsed_ingredients: undefined, rating_value: undefined, rating_count: undefined, total_time: undefined, image: undefined}}
                  actions={[
                    { actionText: "Action 1", actionHandler: () => alert("Action 1 clicked") },
                    { actionText: "Action 2", actionHandler: () => alert("Action 2 clicked") }
                  ]}
                />
              </Carousel>
            </div>

            <div>
              <ToggleableCard handleToggle={handleCardToggle}>
                <p className="text-xs mb-0 pb-0">This is a toggleable card. Click me!</p>
              </ToggleableCard>
            </div>
          </div>
        </div>

        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-2">Sticky Note</h3>
          <StickyNote>
            <p>This is a sticky note with a blue shadow.</p>
          </StickyNote>
        </div>
      </div>
      
      <div>
        <h2 className="text-xl font-bold mb-4">Styling</h2>
        
        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-2">Fonts</h3>
          <h1>Header 1</h1>
          <h2>Header 2</h2>
          <h3>Header 3</h3>
          <h4>Header 4</h4>
          <h5>Header 5</h5>
          <h6>Header 6</h6>
          <p>Paragraph</p>
          <h6>Subtitle</h6>
        </div>
        
        <div>
          <h3 className="text-lg font-semibold mb-2">Colors</h3>
          <div className='bg-primary text-black p-4 m-2'>primary</div>
          <div className='bg-secondary text-black p-4 m-2'>secondary</div>
          <div className='bg-destructive text-black p-4 m-2'>destructive</div>
          <div className='bg-green text-black p-4 m-2'>green</div>
          <div className='bg-blue text-black p-4 m-2'>blue</div>
          <div className='bg-purple text-black p-4 m-2'>purple</div>
          <div className='bg-pink text-black p-4 m-2'>pink</div>
          <div className='bg-yellow text-black p-4 m-2'>yellow</div>
          <div className='bg-orange text-black p-4 m-2'>orange</div>

          <h4 className="text-lg font-semibold mt-4 mb-2">Gray Scale</h4>
          <div className='bg-gray-50 text-black p-4 m-2'>gray-50</div>
          <div className='bg-gray-100 text-black p-4 m-2'>gray-100</div>
          <div className='bg-gray-200 text-black p-4 m-2'>gray-200</div>
          <div className='bg-gray-300 text-black p-4 m-2'>gray-300</div>
          <div className='bg-gray-400 text-black p-4 m-2'>gray-400</div>
          <div className='bg-gray-500 text-white p-4 m-2'>gray-500</div>
          <div className='bg-gray-600 text-white p-4 m-2'>gray-600</div>
          <div className='bg-gray-700 text-white p-4 m-2'>gray-700</div>
          <div className='bg-gray-800 text-white p-4 m-2'>gray-800</div>
          <div className='bg-gray-900 text-white p-4 m-2'>gray-900</div>
          <div className='bg-gray-950 text-white p-4 m-2'>gray-950</div>
        </div>
      </div>
      <div className='mb-8'>
        <NutritionBar protein_calories={1000} fat_calories={100} carb_calories={100} />
        <NutritionBar protein_calories={1000} fat_calories={100} carb_calories={100} size="lg" />
      </div>
      <div className='mb-8'>
        <div className="flex items-center space-x-2">
          <Switch id="airplane-mode" />
          <Label htmlFor="airplane-mode">Airplane Mode</Label>
        </div>
      </div>
      
      <div className="mb-8">
        <h3 className="text-lg font-semibold mb-2">Accordion</h3>
        <Accordion type="single" collapsible className="w-full">
          <AccordionItem value="item-1">
            <AccordionTrigger>Is it accessible?</AccordionTrigger>
            <AccordionContent>
              Yes. It adheres to the WAI-ARIA design pattern.
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-2">
            <AccordionTrigger>Is it styled?</AccordionTrigger>
            <AccordionContent>
              Yes. It comes with default styles that matches the other components&apos; aesthetic.
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-3">
            <AccordionTrigger>Is it animated?</AccordionTrigger>
            <AccordionContent>
              Yes. It&apos;s animated by default, but you can disable it if you prefer.
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>

      <div className="mb-8">
        <h3 className="text-lg font-semibold mb-2">Dropdown Menu</h3>
        <div className="flex gap-4">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="default">Open Dropdown</Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuItem>Profile</DropdownMenuItem>
              <DropdownMenuItem>Settings</DropdownMenuItem>
              <DropdownMenuItem>Help</DropdownMenuItem>
              <DropdownMenuItem>Log out</DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>

          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="noShadow" size="icon">
                <MoreHorizontal className="h-6 w-6" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuItem>Profile</DropdownMenuItem>
              <DropdownMenuItem>Settings</DropdownMenuItem>
              <DropdownMenuItem>Help</DropdownMenuItem>
              <DropdownMenuItem>Log out</DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>

      <div className="mb-8">
        <h3 className="text-lg font-semibold mb-2">Multi Action Button</h3>
        <MultiActionButton 
          actions={multiActions} 
          defaultActionIndex={0} 
          className="my-4"
        />
      </div>
      <div className="bg-ben-day-green w-64 h-64 items-center justify-center flex rounded border border-black">
        <div className="bg-green w-56 h-56 items-center justify-center flex rounded border border-black">
          <span>Test</span>
        </div>
      </div>
      <div className="mb-4">
        <h3 className="text-lg font-semibold mb-2">Sheet</h3>
        <div className="flex space-x-4">
          <Sheet>
            <SheetTrigger asChild>
              <Button variant="default">Open Right Sheet</Button>
            </SheetTrigger>
            <SheetContent side="right">
              <SheetHeader>
                <SheetTitle>Sheet Title</SheetTitle>
                <SheetDescription>
                  This is a description of the sheet content.
                </SheetDescription>
              </SheetHeader>
              <div className="py-4">
                <p>This is the main content of the sheet. You can add any components or text here.</p>
              </div>
              <SheetFooter>
                <Button variant="default">Save Changes</Button>
              </SheetFooter>
            </SheetContent>
          </Sheet>
          <Sheet>
            <SheetTrigger asChild>
              <Button variant="default">Open Left Sheet</Button>
            </SheetTrigger>
            <SheetContent side="left">
              <SheetHeader>
                <SheetTitle>Left Sheet</SheetTitle>
                <SheetDescription>
                  This sheet slides in from the left side.
                </SheetDescription>
              </SheetHeader>
              <div className="py-4">
                <p>Content for the left-side sheet.</p>
              </div>
              <SheetFooter>
                <Button variant="default">Close</Button>
              </SheetFooter>
            </SheetContent>
          </Sheet>

          <Sheet>
            <SheetTrigger asChild>
              <Button variant="default">Open Bottom Sheet</Button>
            </SheetTrigger>
            <SheetContent side="bottom" className="h-[90vh] overflow-y-auto">
              <SheetHeader>
                <SheetTitle>Complex Form Example</SheetTitle>
                <SheetDescription>
                  Fill out this detailed form to test scroll behavior
                </SheetDescription>
              </SheetHeader>
              
              <div className="py-4 space-y-4">
                {/* Button for nested bottom sheet */}
                <Sheet>
                  <SheetTrigger asChild>
                    <Button variant="default">Open Another Bottom Sheet</Button>
                  </SheetTrigger>
                  <SheetContent side="bottom" className="h-[70vh]">
                    <SheetHeader>
                      <SheetTitle>Nested Bottom Sheet</SheetTitle>
                      <SheetDescription>
                        This is a bottom sheet nested within another bottom sheet
                      </SheetDescription>
                    </SheetHeader>
                    <div className="py-4">
                      <p>Content for the nested bottom sheet goes here.</p>
                    </div>
                    <SheetFooter>
                      <Button variant="default">Close</Button>
                    </SheetFooter>
                  </SheetContent>
                </Sheet>

                {/* Right sheet with its own nested bottom sheet button */}
                <Sheet>
                  <SheetTrigger asChild>
                    <Button variant="default">Open Right Sheet</Button>
                  </SheetTrigger>
                  <SheetContent side="right">
                    <SheetHeader>
                      <SheetTitle>Stacked Sheet</SheetTitle>
                      <SheetDescription>
                        This sheet is stacked on top of the bottom sheet
                      </SheetDescription>
                    </SheetHeader>
                    <div className="py-4 space-y-4">
                      <p>You can add any content here and itodeodeod will appear above the bottom sheet.</p>
                      
                      {/* Button for bottom sheet from right sheet */}
                      <Sheet>
                        <SheetTrigger asChild>
                          <Button variant="default">Open Bottom Sheet From Here</Button>
                        </SheetTrigger>
                        <SheetContent side="bottom" className="h-[70vh]">
                          <SheetHeader>
                            <SheetTitle>Bottom Sheet from Right Sheet</SheetTitle>
                            <SheetDescription>
                              This bottom sheet was opened from the right sheet
                            </SheetDescription>
                          </SheetHeader>
                          <div className="py-4">
                            <p>Content for this bottom sheet goes here.</p>
                          </div>
                          <SheetFooter>
                            <Button variant="default">Close</Button>
                          </SheetFooter>
                        </SheetContent>
                      </Sheet>
                    </div>
                    <SheetFooter>
                      <Button variant="default">Close</Button>
                    </SheetFooter>
                  </SheetContent>
                </Sheet>
              </div>

              <div className="py-4 space-y-6">
                <div className="space-y-4">
                  <h4 className="font-semibold">Personal Information</h4>
                  <div className="grid grid-cols-2 gap-4">
                    <div className="space-y-2">
                      <Label>First Name</Label>
                      <Input type="text" placeholder="John" />
                    </div>
                    <div className="space-y-2">
                      <Label>Last Name</Label>
                      <Input type="text" placeholder="Doe" />
                    </div>
                  </div>
                </div>

                <div className="space-y-4">
                  <h4 className="font-semibold">Contact Details</h4>
                  <div className="space-y-4">
                    <div className="space-y-2">
                      <Label>Email Address</Label>
                      <Input type="email" placeholder="john@example.com" />
                    </div>
                    <div className="space-y-2">
                      <Label>Phone Number</Label>
                      <Input type="tel" placeholder="+1 (555) 000-0000" />
                    </div>
                  </div>
                </div>

                <div className="space-y-4">
                  <h4 className="font-semibold">Address</h4>
                  <div className="space-y-4">
                    <div className="space-y-2">
                      <Label>Street Address</Label>
                      <Input type="text" placeholder="123 Main St" />
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                      <div className="space-y-2">
                        <Label>City</Label>
                        <Input type="text" placeholder="New York" />
                      </div>
                      <div className="space-y-2">
                        <Label>State</Label>
                        <Select>
                          <SelectTrigger>
                            <SelectValue placeholder="Select state" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value="ny">New York</SelectItem>
                            <SelectItem value="ca">California</SelectItem>
                            <SelectItem value="tx">Texas</SelectItem>
                          </SelectContent>
                        </Select>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="space-y-4">
                  <h4 className="font-semibold">Additional Information</h4>
                  <div className="space-y-4">
                    <div className="space-y-2">
                      <Label>Bio</Label>
                      <TextArea 
                        placeholder="Tell us about yourself"
                        className="h-32"
                      />
                    </div>
                    <div className="space-y-2">
                      <Label>Interests</Label>
                      <TextArea 
                        placeholder="What are your hobbies?"
                        className="h-32"
                      />
                    </div>
                  </div>
                </div>

                <div className="space-y-4">
                  <h4 className="font-semibold">Preferences</h4>
                  <div className="space-y-4">
                    <div className="flex items-center space-x-2">
                      <Checkbox id="newsletter" />
                      <Label htmlFor="newsletter">Subscribe to newsletter</Label>
                    </div>
                    <div className="flex items-center space-x-2">
                      <Checkbox id="email" />
                      <Label htmlFor="email">Receive email notifications</Label>
                    </div>
                    <div className="flex items-center space-x-2">
                      <Checkbox id="sms" />
                      <Label htmlFor="sms">Allow SMS notifications</Label>
                    </div>
                  </div>
                </div>
              </div>
              
              <SheetFooter>
                <Button variant="default">Submit Form</Button>
              </SheetFooter>
            </SheetContent>
          </Sheet>
        </div>
      </div>
      <div className="mb-4">
        <h3 className="text-lg font-semibold mb-2">Small Button Groups</h3>
        <div className="space-y-4">
          {/* Text Formatting Example */}
          <SmallButtonGroup type="multiple">
            <SmallGroupToggle value="bold" aria-label="Toggle bold">
              <Bold className="h-4 w-4" />
            </SmallGroupToggle>
            <SmallGroupToggle value="italic" aria-label="Toggle italic">
              <Italic className="h-4 w-4" />
            </SmallGroupToggle>
            <SmallGroupToggle value="underline" aria-label="Toggle underline">
              <Underline className="h-4 w-4" />
            </SmallGroupToggle>
          </SmallButtonGroup>

          {/* Text Alignment Example */}
          <SmallButtonGroup type="single" defaultValue="left">
            <SmallGroupToggle value="left" aria-label="Align left">
              <AlignLeft className="h-4 w-4" />
            </SmallGroupToggle>
            <SmallGroupToggle value="center" aria-label="Align center">
              <AlignCenter className="h-4 w-4" />
            </SmallGroupToggle>
            <SmallGroupToggle value="right" aria-label="Align right">
              <AlignRight className="h-4 w-4" />
            </SmallGroupToggle>
          </SmallButtonGroup>

          {/* Mixed Icons and Text Example */}
          <SmallButtonGroup type="single" defaultValue="light">
            <SmallGroupToggle value="light" aria-label="Light mode">
              <Sun className="h-4 w-4 mr-2" />
              Light
            </SmallGroupToggle>
            <SmallGroupToggle value="dark" aria-label="Dark mode">
              <Moon className="h-4 w-4 mr-2" />
              Dark
            </SmallGroupToggle>
            <SmallGroupToggle value="system" aria-label="System mode">
              <Laptop className="h-4 w-4 mr-2" />
              System
            </SmallGroupToggle>
          </SmallButtonGroup>

          {/* Original Examples */}
          <SmallButtonGroup type="single" defaultValue="1">
            <SmallGroupToggle value="1">Option 1</SmallGroupToggle>
            <SmallGroupToggle value="2">Option 2</SmallGroupToggle>
            <SmallGroupToggle value="3">Option 3</SmallGroupToggle>
          </SmallButtonGroup>

          <SmallButtonGroup type="multiple">
            <SmallGroupToggle value="1">Toggle</SmallGroupToggle>
            <SmallGroupButton onClick={() => alert('Clicked!')}>
              Action
            </SmallGroupButton>
            <SmallGroupToggle value="2">Toggle 2</SmallGroupToggle>
          </SmallButtonGroup>
        </div>
      </div>

      <div className="mb-8">
        <h3 className="text-lg font-semibold mb-2">Carousel</h3>
        <div className="space-y-8">
          {/* Default size carousel */}
          <div>
            <h4 className="text-md font-medium mb-2">Default Carousel</h4>
            <Carousel>
              <div className="w-64 h-32 bg-gray-200 rounded flex items-center justify-center mr-2">Slide 1</div>
              <div className="w-48 h-32 bg-gray-300 rounded flex items-center justify-center mr-2">Slide 2</div>
              <div className="w-56 h-32 bg-gray-400 rounded flex items-center justify-center mr-2">Slide 3</div>
              <div className="w-72 h-32 bg-gray-500 rounded flex items-center justify-center mr-2 text-white">Slide 4</div>
              <div className="w-64 h-32 bg-gray-600 rounded flex items-center justify-center mr-2 text-white">Slide 5</div>
            </Carousel>
          </div>

          {/* Small size carousel */}
          <div>
            <h4 className="text-md font-medium mb-2">Small Carousel</h4>
            <Carousel size="sm">
              <div className="w-48 h-24 bg-blue/20 rounded flex items-center justify-center mr-2">Small 1</div>
              <div className="w-36 h-24 bg-blue/30 rounded flex items-center justify-center mr-2">Small 2</div>
              <div className="w-40 h-24 bg-blue/40 rounded flex items-center justify-center mr-2">Small 3</div>
              <div className="w-52 h-24 bg-blue/50 rounded flex items-center justify-center mr-2">Small 4</div>
            </Carousel>
          </div>

          {/* Large size carousel */}
          <div>
            <h4 className="text-md font-medium mb-2">Large Carousel</h4>
            <Carousel size="lg">
              <div className="w-80 h-40 bg-primary/20 rounded flex items-center justify-center mr-2">Large 1</div>
              <div className="w-72 h-40 bg-primary/30 rounded flex items-center justify-center mr-2">Large 2</div>
              <div className="w-96 h-40 bg-primary/40 rounded flex items-center justify-center mr-2">Large 3</div>
              <div className="w-80 h-40 bg-primary/50 rounded flex items-center justify-center mr-2">Large 4</div>
            </Carousel>
          </div>

          {/* Example with cards */}
          <div>
            <h4 className="text-md font-medium mb-2">Card Carousel</h4>
            <Carousel>
              {[1, 2, 3, 4, 5].map((item) => (
                <div key={item} className="w-72">
                  <ToggleableCard handleToggle={() => console.log(`Card ${item} toggled`)}>
                    <div className="p-4">
                      <h3 className="font-semibold mb-2">Card {item}</h3>
                      <p className="text-sm">This is a card in a carousel. Each card can have its own width.</p>
                    </div>
                  </ToggleableCard>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </div>

      <div className="mb-8">
        <h3 className="text-lg font-semibold mb-2">Loading Cards</h3>
        <div className="space-y-4">
          {/* Default green loading card with icon */}
          <LoadingCard>
            <div className="p-4 flex items-center gap-2">
              <Loader2 className="h-4 w-4" />
              <span>Loading with default color...</span>
            </div>
          </LoadingCard>

          {/* Custom color variants */}
          <div className="flex flex-wrap gap-4">
            <LoadingCard color="#3b82f6" isLoading={true}>
              <div className="p-4">
                <span>Blue loading border</span>
              </div>
            </LoadingCard>

            <LoadingCard color="#ef4444" isLoading={true}>
              <div className="p-4">
                <span>Red loading border</span>
              </div>
            </LoadingCard>

            <LoadingCard color="#8b5cf6" isLoading={true}>
              <div className="p-4">
                <span>Purple loading border</span>
              </div>
            </LoadingCard>
          </div>

          {/* Loading state toggle example */}
          <LoadingCard isLoading={false}>
            <div className="p-4">
              <span>Not loading (isLoading=false)</span>
            </div>
          </LoadingCard>
        </div>
      </div>

      <div className="mb-8">
        <h3 className="text-lg font-semibold mb-2">Drawers</h3>
        <div className="flex space-x-4">
          {/* Basic Drawer */}
          <Drawer>
            <DrawerTrigger asChild>
              <Button variant="default">Open Basic Drawer</Button>
            </DrawerTrigger>
            <DrawerContent>
              <DrawerHeader>
                <DrawerTitle>Basic Drawer</DrawerTitle>
                <DrawerDescription>
                  This is a basic drawer that slides up from the bottom.
                </DrawerDescription>
              </DrawerHeader>
              <div className="p-4">
                <p>Main content area of the drawer.</p>
              </div>
              <DrawerFooter>
                <Button variant="default">Save changes</Button>
              </DrawerFooter>
            </DrawerContent>
          </Drawer>

          {/* Drawer with Sheet */}
          <Drawer>
            <DrawerTrigger asChild>
              <Button variant="default">Drawer + Sheet</Button>
            </DrawerTrigger>
            <DrawerContent>
              <DrawerHeader>
                <DrawerTitle>Drawer with Sheet</DrawerTitle>
                <DrawerDescription>
                  Click the button below to open a sheet
                </DrawerDescription>
              </DrawerHeader>
              <div className="p-4">
                <Sheet>
                  <SheetTrigger asChild>
                    <Button>Open Side Sheet</Button>
                  </SheetTrigger>
                  <SheetContent>
                    <SheetHeader>
                      <SheetTitle>Sheet Inside Drawer</SheetTitle>
                      <SheetDescription>
                        This sheet opens on top of the drawer
                      </SheetDescription>
                    </SheetHeader>
                    <div className="py-4">
                      <p>Sheet content goes here</p>
                    </div>
                    <SheetFooter>
                      <Button variant="default">Close</Button>
                    </SheetFooter>
                  </SheetContent>
                </Sheet>
              </div>
              <DrawerFooter>
                <Button variant="default">Close Drawer</Button>
              </DrawerFooter>
            </DrawerContent>
          </Drawer>

          {/* Drawer with Form */}
          <Drawer>
            <DrawerTrigger asChild>
              <Button variant="default">Form Drawer</Button>
            </DrawerTrigger>
            <DrawerContent>
              <DrawerHeader>
                <DrawerTitle>Form Example</DrawerTitle>
                <DrawerDescription>
                  Fill out this form in the drawer
                </DrawerDescription>
              </DrawerHeader>
              <div className="p-4 space-y-4">
                <div className="space-y-2">
                  <Label htmlFor="name">Name</Label>
                  <Input id="name" placeholder="Enter your name" />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="email">Email</Label>
                  <Input id="email" type="email" placeholder="Enter your email" />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="message">Message</Label>
                  <TextArea id="message" placeholder="Enter your message" />
                </div>
              </div>
              <DrawerFooter>
                <Button variant="default">Submit</Button>
              </DrawerFooter>
            </DrawerContent>
          </Drawer>
        </div>
      </div>

      <div className="mb-8">
        <h3 className="text-lg font-semibold mb-2">Radio Groups</h3>
        <div className="space-y-4">
          {/* Basic Radio Group */}
          <div className="flex flex-col gap-2">
            <Label>Basic Radio Group</Label>
            <RadioGroup defaultValue="option1">
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="option1" id="option1" />
                <Label htmlFor="option1">Option 1</Label>
              </div>
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="option2" id="option2" />
                <Label htmlFor="option2">Option 2</Label>
              </div>
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="option3" id="option3" />
                <Label htmlFor="option3">Option 3</Label>
              </div>
            </RadioGroup>
          </div>

          {/* Basic Radio Group */}
          <div className="flex flex-col gap-2">
            <Label>Basic Radio Group</Label>
            <RadioGroup defaultValue="option1">
              <div className="flex items-start space-x-2">
                <RadioGroupItem value="option1" id="option1" />
                <div className="flex flex-col">
                  <Label htmlFor="option1" className="font-bold mt-1 mb-2">First Option</Label>
                  <span className="text-sm text-gray-600">Select this for option 1</span>
                </div>
              </div>
              <div className="flex items-start space-x-2">
                <RadioGroupItem value="option2" id="option2" />
                <div className="flex flex-col">
                  <Label htmlFor="option2" className="font-bold mt-1 mb-2">Second Option</Label>
                  <span className="text-sm text-gray-600">Select this for option 2</span>
                </div>
              </div>
              <div className="flex items-start space-x-2">
                <RadioGroupItem value="option3" id="option3" />
                <div className="flex flex-col">
                  <Label htmlFor="option3" className="font-bold mt-1 mb-2">Third Option</Label>
                  <span className="text-sm text-gray-600">Select this for option 3</span>
                </div>
              </div>
            </RadioGroup>
          </div>

          <div className="flex flex-col gap-2">
            <Label>Basic Radio Group</Label>
            <RadioGroup defaultValue="option1">
              <div className="flex flex-col space-y-1">
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="option1" id="option1" />
                  <Label htmlFor="option1" className="font-bold">First Option</Label>
                </div>
                <span className="text-sm text-gray-600 ml-7">Select this for option 1</span>
              </div>
              <div className="flex flex-col space-y-1">
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="option2" id="option2" />
                  <Label htmlFor="option2" className="font-bold">Second Option</Label>
                </div>
                <span className="text-sm text-gray-600 ml-7">Select this for option 2</span>
              </div>
              <div className="flex flex-col space-y-1">
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="option3" id="option3" />
                  <Label htmlFor="option3" className="font-bold">Third Option</Label>
                </div>
                <span className="text-sm text-gray-600 ml-7">Select this for option 3</span>
              </div>
            </RadioGroup>
          </div>

          {/* Disabled Radio Group */}
          <div className="flex flex-col gap-2">
            <Label>Disabled Radio Group</Label>
            <RadioGroup defaultValue="option1" disabled>
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="option1" id="disabled1" />
                <Label htmlFor="disabled1">Disabled Option 1</Label>
              </div>
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="option2" id="disabled2" />
                <Label htmlFor="disabled2">Disabled Option 2</Label>
              </div>
            </RadioGroup>
          </div>

          {/* Horizontal Radio Group */}
          <div className="flex flex-col gap-2">
            <Label>Horizontal Radio Group</Label>
            <RadioGroup defaultValue="horizontal1" className="flex space-x-4">
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="horizontal1" id="horizontal1" />
                <Label htmlFor="horizontal1">Horizontal 1</Label>
              </div>
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="horizontal2" id="horizontal2" />
                <Label htmlFor="horizontal2">Horizontal 2</Label>
              </div>
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="horizontal3" id="horizontal3" />
                <Label htmlFor="horizontal3">Horizontal 3</Label>
              </div>
            </RadioGroup>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Styling;

'use client'

import * as React from 'react'
import * as RadioGroupPrimitives from '@radix-ui/react-radio-group'
import { cn } from 'src/lib/utils'

const RadioGroup = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitives.Root>
>(({ className, ...props }, ref) => (
  <RadioGroupPrimitives.Root
    className={cn('grid gap-2', className)}
    {...props}
    ref={ref}
  />
))

const RadioGroupItem = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitives.Item>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitives.Item>
>(({ className, ...props }, ref) => (
  <RadioGroupPrimitives.Item
    ref={ref}
    className={cn(
      'aspect-square h-5 w-5 rounded-full border border-black bg-white',
      'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-black focus-visible:ring-offset-2',
      'disabled:cursor-not-allowed disabled:opacity-50',
      className
    )}
    {...props}
  >
    <RadioGroupPrimitives.Indicator className="flex items-center justify-center">
      <div className="h-2.5 w-2.5 rounded-full bg-primary" />
    </RadioGroupPrimitives.Indicator>
  </RadioGroupPrimitives.Item>
))

RadioGroup.displayName = RadioGroupPrimitives.Root.displayName
RadioGroupItem.displayName = RadioGroupPrimitives.Item.displayName

export { RadioGroup, RadioGroupItem } 
import React, { useMemo } from 'react';

const NutritionTable = ({ meal, leftovers, recipes, householdDict }) => {
  // Calculate target nutrition based on meal recipients and leftovers
  const targetNutrition = useMemo(() => {
    // Skip calculation if no recipients
    if (!meal?.meal_recipients?.length && !leftovers?.length) return null;

    // Combine recipients from current meal and all leftover meals
    const allMeals = [meal, ...(leftovers || [])];
    const processedRecipients = new Set();
    
    return allMeals.reduce((mealAcc, currentMeal) => {
      if (!currentMeal.meal_recipients) return mealAcc;
      
      return currentMeal.meal_recipients.reduce((recipientAcc, recipientId) => {
        if (processedRecipients.has(recipientId)) return recipientAcc;
        processedRecipients.add(recipientId);

        const user = householdDict[recipientId];
        if (!user) return recipientAcc;

        const mealCalories = user.calorie_target * user.calorie_allocation[currentMeal.meal_type.toLowerCase()];
        const proteinCals = mealCalories * user.protein_percentage;
        const fatCals = mealCalories * user.fat_percentage;
        const carbCals = mealCalories * user.carb_percentage;

        return {
          protein_calories: recipientAcc.protein_calories + proteinCals,
          fat_calories: recipientAcc.fat_calories + fatCals,
          carbs_calories: recipientAcc.carbs_calories + carbCals,
        };
      }, mealAcc);
    }, { protein_calories: 0, fat_calories: 0, carbs_calories: 0 });
  }, [meal, leftovers, householdDict]);

  // Calculate total nutrition for the meal
  const totalNutrition = useMemo(() => {
    return recipes.reduce((acc, recipeData) => {
      const { nutrition } = recipeData.recipe;
      const multiplier = recipeData.multiplier;
      return {
        calories: acc.calories + (nutrition.calories * multiplier),
        protein_calories: acc.protein_calories + (nutrition.protein_calories * multiplier),
        fat_calories: acc.fat_calories + (nutrition.fat_calories * multiplier),
        carbs_calories: acc.carbs_calories + (nutrition.carbs_calories * multiplier),
      };
    }, { calories: 0, protein_calories: 0, fat_calories: 0, carbs_calories: 0 });
  }, [recipes]);

  if (!targetNutrition && recipes.length === 0) return null;

  const hasTargetRow = !!targetNutrition;
  const hasPlannedRow = recipes.length > 0;
  const showNeededRow = hasTargetRow && hasPlannedRow;

  // Calculate needed values
  const neededNutrition = hasTargetRow && hasPlannedRow ? {
    calories: Math.round((targetNutrition.protein_calories / 4 * 4 + 
                       targetNutrition.carbs_calories / 4 * 4 + 
                       targetNutrition.fat_calories / 9 * 9) - totalNutrition.calories),
    protein: Math.round(targetNutrition.protein_calories / 4 - totalNutrition.protein_calories / 4),
    carbs: Math.round(targetNutrition.carbs_calories / 4 - totalNutrition.carbs_calories / 4),
    fat: Math.round(targetNutrition.fat_calories / 9 - totalNutrition.fat_calories / 9)
  } : null;

  return (
    <div className="max-w-sm">
      <div className="rounded border border-black overflow-hidden">
        <table className="min-w-full text-xs border-separate border-spacing-0">
          <thead>
            <tr>
              <th className="text-left py-2 px-4 border-b border-r border-black"></th>
              <th className="text-right py-2 px-4 border-b border-r border-black">Calories</th>
              <th className="text-right py-2 px-4 border-b border-r border-black">Protein</th>
              <th className="text-right py-2 px-4 border-b border-r border-black">Fat</th>
              <th className="text-right py-2 px-4 border-b border-black">Carbs</th>
            </tr>
          </thead>
          <tbody>
            {targetNutrition && (
              <tr>
                <td className={`py-1 px-4 border-r border-black ${hasPlannedRow ? 'border-b' : ''}`}>Target</td>
                <td className={`text-right px-4 border-r border-black ${hasPlannedRow ? 'border-b' : ''}`}>
                  {Math.round(targetNutrition.protein_calories / 4 * 4 + 
                            targetNutrition.carbs_calories / 4 * 4 + 
                            targetNutrition.fat_calories / 9 * 9)}
                </td>
                <td className={`text-right px-4 border-r border-black ${hasPlannedRow ? 'border-b' : ''}`}>
                  {Math.round(targetNutrition.protein_calories / 4)}g
                </td>
                <td className={`text-right px-4 border-r border-black ${hasPlannedRow ? 'border-b' : ''}`}>
                  {Math.round(targetNutrition.fat_calories / 9)}g
                </td>
                <td className={`text-right px-4 ${hasPlannedRow ? 'border-b border-black' : ''}`}>
                  {Math.round(targetNutrition.carbs_calories / 4)}g
                </td>
              </tr>
            )}
            {recipes.length > 0 && (
              <tr>
                <td className={`py-1 px-4 border-r border-black ${showNeededRow ? 'border-b' : ''}`}>Planned</td>
                <td className={`text-right px-4 border-r border-black ${showNeededRow ? 'border-b' : ''}`}>
                  {Math.round(totalNutrition.calories)}
                </td>
                <td className={`text-right px-4 border-r border-black ${showNeededRow ? 'border-b' : ''}`}>
                  {Math.round(totalNutrition.protein_calories / 4)}g
                </td>
                <td className={`text-right px-4 border-r border-black ${showNeededRow ? 'border-b' : ''}`}>
                  {Math.round(totalNutrition.fat_calories / 9)}g
                </td>
                <td className={`text-right px-4 ${showNeededRow ? 'border-b border-black' : ''}`}>
                  {Math.round(totalNutrition.carbs_calories / 4)}g
                </td>
              </tr>
            )}
            {showNeededRow && (
              <tr>
                <td className="py-1 px-4 border-r border-black">Needed</td>
                <td className={`text-right px-4 border-r border-black ${
                  Math.abs(neededNutrition.calories) > ((targetNutrition.protein_calories + 
                    targetNutrition.carbs_calories + 
                    targetNutrition.fat_calories) * 0.1) ? 'bg-pink' : ''
                }`}>{neededNutrition.calories}</td>
                <td className={`text-right px-4 border-r border-black ${
                  Math.abs(neededNutrition.protein) > ((targetNutrition.protein_calories / 4) * 0.1) ? 'bg-pink' : ''
                }`}>{neededNutrition.protein}g</td>
                <td className={`text-right px-4 border-r border-black ${
                  Math.abs(neededNutrition.fat) > ((targetNutrition.fat_calories / 9) * 0.1) ? 'bg-pink' : ''
                }`}>{neededNutrition.fat}g</td>
                <td className={`text-right px-4 ${
                  Math.abs(neededNutrition.carbs) > ((targetNutrition.carbs_calories / 4) * 0.1) ? 'bg-pink' : ''
                }`}>{neededNutrition.carbs}g</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default NutritionTable;
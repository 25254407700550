import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setGeneratedRecipeId } from './recipeGenerationSlice';
import RecipeGenerationHeader from './RecipeGenerationHeader';
import RecipeGenerationResponse from './RecipeGenerationResponse';
import RecipeGenerationChat from './RecipeGenerationChat';
import RecipeGenerationRecipePreview from './RecipeGenerationRecipePreview';
import NavbarContext from '../../NavBarContext';
import FooterContext from '../../FooterContext';

const RecipeGeneration = () => {
    const { generated_recipe_id } = useParams();
    const dispatch = useDispatch();
    const { setShowNavbar } = useContext(NavbarContext);
    const { setShowFooter } = useContext(FooterContext);

    useEffect(() => {
        // Set recipe ID from URL if it exists
        if (generated_recipe_id) {
            dispatch(setGeneratedRecipeId(generated_recipe_id));
        }

        // Show navbar, hide footer
        setShowNavbar(true);
        setShowFooter(false);

        // Cleanup function to show footer and reset recipe ID when component unmounts
        return () => {
            setShowFooter(true);
            dispatch(setGeneratedRecipeId(null));
        };
    }, [setShowNavbar, setShowFooter, generated_recipe_id, dispatch]);

    return (
        <div className="flex h-[calc(100vh-64px)]">
            {/* Main content area */}
            <div className="flex-1 flex flex-col overflow-hidden">
                {/* Pinned header at top */}
                <div className="flex-shrink-0 border-b border-black w-full">
                    <div className="flex flex-col items-center w-full">
                        <div className="w-full max-w-7xl">
                            <RecipeGenerationHeader />
                        </div>
                    </div>
                </div>
                {/* Scrollable content */}
                <div className="flex-grow overflow-y-auto">
                    <div className="flex flex-col items-center w-full">
                        <div className="w-full max-w-7xl">
                            <RecipeGenerationResponse />
                        </div>
                    </div>
                </div>
                {/* Pinned chat at bottom */}
                <div className="flex-shrink-0 border-t border-black w-full">
                    <div className="flex flex-col items-center w-full">
                        <div className="w-full max-w-7xl">
                            <RecipeGenerationChat />
                        </div>
                    </div>
                </div>
            </div>

            {/* Recipe preview will handle its own width and animation */}
            <RecipeGenerationRecipePreview />
        </div>
    );
};

export default RecipeGeneration; 
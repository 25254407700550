import React from 'react';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { useGetGeneratedRecipeQuery } from '../../store/apiSlice';
import RecipeFull from '../../components/RecipeFull';

const RecipeGenerationRecipePreview = () => {
    const generatedRecipeId = useSelector((state) => state.recipeGeneration.generatedRecipeId);
    const { data, error, isLoading } = useGetGeneratedRecipeQuery(generatedRecipeId, {
        skip: !generatedRecipeId,
    });

    // Only render if we have recipe data
    if (!data || !data?.recipes || isLoading || !generatedRecipeId) {
        return null;
    }

    if (error) {
        console.error("Error fetching generated recipe:", error);
        return null;
    }

    return (
        <motion.div
            layout
            initial={{ x: 400 }}
            animate={{ x: 0 }}
            transition={{ duration: 1 }}
            className="w-[400px] border-l border-gray-200"
        >
            <div className="h-full overflow-y-auto p-4 border-l border-black">
                <RecipeFull 
                    recipe={data.recipes[Math.max(...Object.keys(data.recipes).map(Number))]} 
                    showExternalLink={false}
                />
            </div>
        </motion.div>
    );
};

export default RecipeGenerationRecipePreview;
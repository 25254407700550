"use client"

import * as React from "react"
import * as ToggleGroupPrimitive from "@radix-ui/react-toggle-group"
import { cva, type VariantProps } from "class-variance-authority"
import { cn } from "src/lib/utils"

const smallButtonGroupVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-sm text-sm font-medium transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default: 'bg-white hover:bg-gray-50 border-r border-black last:border-r-0 data-[state=on]:bg-primary',
      },
      size: {
        default: 'h-8 px-3',
        sm: 'h-7 px-1',
        lg: 'h-9 px-4',
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
)

const SmallButtonGroupContext = React.createContext<
  VariantProps<typeof smallButtonGroupVariants>
>({
  size: "default",
  variant: "default",
})

const SmallButtonGroup = React.forwardRef<
  React.ElementRef<typeof ToggleGroupPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof ToggleGroupPrimitive.Root> &
    VariantProps<typeof smallButtonGroupVariants>
>(({ className, variant, size, children, ...props }, ref) => (
  <ToggleGroupPrimitive.Root
    ref={ref}
    className={cn(
      "inline-flex items-center justify-center border border-black rounded-base overflow-hidden",
      className
    )}
    {...props}
  >
    <SmallButtonGroupContext.Provider value={{ variant, size }}>
      {children}
    </SmallButtonGroupContext.Provider>
  </ToggleGroupPrimitive.Root>
))

SmallButtonGroup.displayName = "SmallButtonGroup"

const SmallGroupToggle = React.forwardRef<
  React.ElementRef<typeof ToggleGroupPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof ToggleGroupPrimitive.Item> &
    VariantProps<typeof smallButtonGroupVariants>
>(({ className, children, variant, size, ...props }, ref) => {
  const context = React.useContext(SmallButtonGroupContext)

  return (
    <ToggleGroupPrimitive.Item
      ref={ref}
      className={cn(
        smallButtonGroupVariants({
          variant: context.variant || variant,
          size: context.size || size,
        }),
        className
      )}
      {...props}
    >
      {children}
    </ToggleGroupPrimitive.Item>
  )
})

SmallGroupToggle.displayName = "SmallGroupToggle"

const SmallGroupButton = React.forwardRef<
  HTMLButtonElement,
  React.ButtonHTMLAttributes<HTMLButtonElement> &
    VariantProps<typeof smallButtonGroupVariants>
>(({ className, children, variant, size, ...props }, ref) => {
  const context = React.useContext(SmallButtonGroupContext)

  return (
    <button
      ref={ref}
      className={cn(
        smallButtonGroupVariants({
          variant: context.variant || variant,
          size: context.size || size,
        }),
        className
      )}
      {...props}
    >
      {children}
    </button>
  )
})

SmallGroupButton.displayName = "SmallGroupButton"

export { SmallButtonGroup, SmallGroupToggle, SmallGroupButton, smallButtonGroupVariants }

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    currentPrompt: '',
    generatedRecipeId: null,
    recipeChatStreaming: false,
};

const recipeGenerationSlice = createSlice({
    name: 'recipeGeneration',
    initialState,
    reducers: {
        setCurrentPrompt: (state, action) => {
            state.currentPrompt = action.payload;
        },
        setGeneratedRecipeId: (state, action) => {
            state.generatedRecipeId = action.payload;
        },
        setRecipeChatStreaming: (state, action) => {
            state.recipeChatStreaming = action.payload;
        },
        resetState: () => initialState,
    },
});

export const { setCurrentPrompt, setGeneratedRecipeId, setRecipeChatStreaming, resetState } = recipeGenerationSlice.actions;
export default recipeGenerationSlice.reducer; 
import * as React from "react"
import { cva, type VariantProps } from "class-variance-authority"
import { cn } from "src/lib/utils"

const toggleableCardVariants = cva(
  "inline-flex w-fit p-2 rounded border transition-all duration-100 cursor-pointer",
  {
    variants: {
      variant: {
        default: "border-black",
        bordered: "border-2",
      },
      state: {
        active: "bg-gray-700 text-gray-200 hover:bg-gray-700/90 border-white scale-95",
        inactive: "bg-white text-black hover:bg-gray-100 border-black scale-100",
      },
      maxWidth: {
        xs: "max-w-xs",
        s: "max-w-sm",
        m: "max-w-md",
        l: "max-w-lg",
        xl: "max-w-xl",
        full: "max-w-full",
      }
    },
    defaultVariants: {
      variant: "default",
      state: "inactive",
      maxWidth: "xs",
    },
  }
)

interface ToggleableCardProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof toggleableCardVariants> {
  onToggle?: (isActive: boolean) => void
  isActive?: boolean
  maxWidth?: "xs" | "s" | "m" | "l" | "xl" | "full"
}

const ToggleableCard = React.forwardRef<HTMLDivElement, ToggleableCardProps>(
  ({ className, variant, maxWidth, onToggle, isActive = false, children, ...props }, ref) => {
    const [isActiveInternal, setIsActiveInternal] = React.useState(isActive)

    const handleToggle = React.useCallback(() => {
      const newState = !isActiveInternal
      setIsActiveInternal(newState)
      onToggle?.(newState)
    }, [isActiveInternal, onToggle])

    return (
      <div
        ref={ref}
        className={cn(
          toggleableCardVariants({ 
            variant, 
            maxWidth,
            state: isActiveInternal ? "active" : "inactive",
            className 
          })
        )}
        onClick={handleToggle}
        {...props}
      >
        {children}
      </div>
    )
  }
)

ToggleableCard.displayName = "ToggleableCard"

interface ControlledToggleableCardProps extends Omit<ToggleableCardProps, 'onToggle'> {
  onToggle: (isActive: boolean) => void
  isActive: boolean
}

const ControlledToggleableCard = React.forwardRef<HTMLDivElement, ControlledToggleableCardProps>(
  ({ className, variant, maxWidth, onToggle, isActive, children, ...props }, ref) => {
    const handleToggle = React.useCallback(() => {
      onToggle(!isActive)
    }, [isActive, onToggle])

    return (
      <div
        ref={ref}
        className={cn(
          toggleableCardVariants({ 
            variant, 
            maxWidth,
            state: isActive ? "active" : "inactive",
            className 
          })
        )}
        onClick={handleToggle}
        {...props}
      >
        {children}
      </div>
    )
  }
)

ControlledToggleableCard.displayName = "ControlledToggleableCard"

export { ToggleableCard, ControlledToggleableCard, toggleableCardVariants }
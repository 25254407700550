import RecipeItem from '../../components/RecipeItem';
import { Button } from '../../components/Button';
import { useAddRecipeToMealMutation } from '../../store/apiSlice';
import { useDispatch } from 'react-redux';
import { 
    openExternalSearchRecipeSheet,
    openCreateGeneratedRecipeSheet 
} from './mealPlanSlice';

const HydratedRecipeIdea = ({ idea, mealId }) => {
    const [addRecipeToMeal] = useAddRecipeToMealMutation();
    const dispatch = useDispatch();

    const handleAddToMeal = async (recipeId) => {
        try {
            await addRecipeToMeal({
                meal_id: mealId,
                recipe_id: recipeId,
                multiplier: 1
            });
        } catch (error) {
            console.error('Failed to add recipe to meal:', error);
        }
    };

    return (
        <div className="flex flex-col gap-1 items-start mr-2">
            <RecipeItem
                recipe={idea.recipe}
                suppressProcessing={true}
                actions={[
                    {
                        actionText: 'Add to meal',
                        actionHandler: () => handleAddToMeal(idea.recipe.recipe_id)
                    }
                ]}
            />
            {idea.function === 'find_internet_recipe' && (
                <Button 
                    variant="link" 
                    size="sm"
                    onClick={() => dispatch(openExternalSearchRecipeSheet())}
                >
                    Find other recipes
                </Button>
            )}
            {idea.function === 'build_simple_ai_recipe' && (
                <Button 
                    variant="link" 
                    size="sm"
                    onClick={() => dispatch(openCreateGeneratedRecipeSheet())}
                >
                    Edit recipe
                </Button>
            )}
        </div>
    );
};

export default HydratedRecipeIdea; 
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button } from 'src/components/Button';
import { motion } from 'framer-motion';
import { resetState } from './recipeGenerationSlice';

const RecipeGenerationHeader = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const generatedRecipeId = useSelector((state) => state.recipeGeneration.generatedRecipeId);
    const isNew = !generatedRecipeId;

    const handleCreateNewRecipe = () => {
        dispatch(resetState());
        navigate('/create_recipe');
    };

    return (
        <div className={`px-4 ${generatedRecipeId ? 'py-2' : 'py-1'} ${isNew ? '' : 'flex justify-between items-center'}`}>
            <motion.div layout layoutId="recipe-generation-header" className={`font-bold inline-block ${isNew ? 'text-4xl' : 'text-lg'}`}>
                {isNew ? 'Create new recipe' : 'Edit recipe'}
            </motion.div>
            {!isNew && (
                <Button 
                    variant="neutralNoShadow"
                    size="sm"
                    onClick={handleCreateNewRecipe}
                >
                    Create another recipe
                </Button>
            )}
        </div>
    );
};

export default RecipeGenerationHeader; 
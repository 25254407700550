import './tailwind.css';
import './globals.css';
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import NavbarContext from './NavBarContext';
import FooterContext from './FooterContext';
import RecipeLibrary from './apps/recipeLibrary/RecipeLibrary';
import SignIn from './apps/auth/SignIn';
import Navbar from './components/Navbar';
import Profile from './apps/profile/Profile';
import Styling from './apps/testApps/Styling';
import Animations from './apps/testApps/Animations';
import MealList from './apps/mealSchedule/MealList';
import GroceryList from './apps/groceryList/GroceryList';
import WebSocketTest from './components/WebSocketTest';
import Meal from './apps/mealSchedule/Meal';
import Footer from './components/Footer';
import GroceryListList from './apps/groceryList/GroceryListList';
import BuildMealPlanStructure from './apps/mealPlan/BuildMealPlanStructure';
import MealPlan from './apps/mealPlan/MealPlan';
import Changelog from './apps/changelog/changelog';
import CookModeProto from './apps/cook_mode/CookModeProto';
import ProtectedRoute from './apps/auth/ProtectedRoute';
import RecipeSheet from './components/RecipeSheet';
import RecipeGeneration from './apps/recipeGeneration/RecipeGeneration';

function App() {
  const [showNavbar, setShowNavbar] = useState(true);
  const [showFooter, setShowFooter] = useState(true);

  return (
    <NavbarContext.Provider value={{ showNavbar, setShowNavbar }}>
      <FooterContext.Provider value={{ showFooter, setShowFooter }}>
        <Router>
          <div className="bg-gray-100 min-h-screen flex flex-col">
            {showNavbar && <Navbar />}
            <div className="flex-grow">
              <Routes>
                {/* Public routes */}
                <Route path="/signin" element={<SignIn />} />
                <Route path="/styling" element={<Styling />} />
                <Route path="/animations" element={<Animations />} />
                <Route path="/cook_mode_proto" element={<CookModeProto />} />

                {/* Protected routes */}
                <Route path="/grocery_list/:groceryListId" element={<ProtectedRoute><GroceryList /></ProtectedRoute>} />
                <Route path="/" element={<Navigate to="/schedule" />} />
                <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
                <Route path="/recipes" element={<ProtectedRoute><RecipeLibrary /></ProtectedRoute>} />
                <Route path="/grocery_lists" element={<ProtectedRoute><GroceryListList/></ProtectedRoute>} />
                <Route path="/build_meal_plan" element={<ProtectedRoute><BuildMealPlanStructure /></ProtectedRoute>} />
                <Route path="/meal_plan/:meal_plan_id" element={<ProtectedRoute><MealPlan /></ProtectedRoute>} />
                <Route path="/create_recipe" element={<ProtectedRoute><RecipeGeneration /></ProtectedRoute>} />
                <Route path="/create_recipe/:generated_recipe_id" element={<ProtectedRoute><RecipeGeneration /></ProtectedRoute>} />
                <Route path="/schedule" element={<ProtectedRoute><MealList /></ProtectedRoute>} />
                <Route path="/meal/:meal_id" element={<ProtectedRoute><Meal /></ProtectedRoute>} />
                <Route path="/websocket_test" element={<ProtectedRoute><WebSocketTest /></ProtectedRoute>} />
                <Route path="/changelog" element={<ProtectedRoute><Changelog /></ProtectedRoute>} />
              </Routes>
            </div>
            {showFooter && <Footer />}
            <RecipeSheet />
          </div>
        </Router>
      </FooterContext.Provider>
    </NavbarContext.Provider>
  );
}

export default App;
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../../utils';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import Card from '../../components/Card';
import { Badge } from 'src/components/Badge';
import localIcon from '../../assets/cooking_icon.png';

export default function ScheduledMeal({ meal, mealType }) {
    const navigate = useNavigate();
    const isLeftover = meal && meal.primary_meal !== null;
    console.log("Meal:", meal);

    const handleCardClick = (mealId) => {
        console.log("Card clicked");
        if (isLeftover && meal.primary_meal) {
            navigate(`/meal/${meal.primary_meal.meal_id}`);
        } else if (mealId) {
            navigate(`/meal/${mealId}`);
        }
    };

    const renderMeal = (mealName, mealId, recipes) => {
        return (
            <Card 
                key={mealId} 
                boxShadowColor='green'
                className="cursor-pointer hover:shadow-lg transition-shadow duration-300 hover:bg-white p-2"
                onClick={() => handleCardClick(mealId)}
            >
                <div>
                    <h5 className="font-bold mb-2">
                        {isLeftover ? 'Eat' : 'Cook'} {mealName.charAt(0).toUpperCase() + mealName.slice(1)}
                    </h5>
                    {isLeftover && (
                        <Badge variant="destructive" className="mb-2">
                            Leftovers from {formatDate(meal.primary_meal.date)}'s {meal.primary_meal.meal_type}
                        </Badge>
                    )}
                    {recipes.map(recipeQuantity => (
                        <div key={recipeQuantity.recipe.recipe_id} className="flex items-center py-2">
                            <img src={recipeQuantity.recipe.favicon || localIcon} alt="favicon" className="w-4 h-4 mr-2" />
                            <span className="text-black text-sm">{recipeQuantity.recipe.headline}</span>
                        </div>
                    ))}
                </div>
            </Card>
        );
    };

    if (!meal) {
        return renderMeal(mealType, null, []);
    } else {
        return renderMeal(meal.name || meal.meal_type, meal.id, meal.recipes);
    }
}

export const testRecipeExternal = {
    "id": "zow86YJuRhELaCwrvVhT",
    "updated_at": "2024-05-25T17:24:50.036300+00:00",
    "recipe_id": "nwKv63wGPqIxTSLy5U5h",
    "recipe_type": "external",
    "queued": false,
    "url": "https://cooking.nytimes.com/recipes/1016084-creamy-polenta-with-mushrooms",
    "headline": "Creamy Polenta With Mushrooms",
    "domain": "cooking.nytimes.com",
    "domain_name": "NYT Cooking",
    "recipe_yield": 6,
    "rating_count": 20,
    "rating_value": 4.7,
    "total_time": "PT150M",
    "timing": {
        "totalTime": "PT150M",
        "cookTime": "PT135M",
    },
    "nutrition": {
        "calories": 1000,
        "fat_calories": 600,
        "protein_calories": 200,
        "carbs_calories": 200,
    },
    "description": "Who knows who first mixed soy sauce and butter and discovered the pleasures the combination provides. Try the mixture on warm white rice, a steaming pile of greens or an old sneaker – regardless, the taste is a sublime velvet of sweet and salty, along with a kind of pop we call umami, a fifth taste beyond sweet, sour, bitter and salty. Soy butter provides warmth and luxury, elegance without pomp. For this recipe, we’ve adapted a dish that was on the menu at the chef Chris Jaeckle’s All’onda, in Manhattan: a mixture of soy and butter with mushroom stock to pour over polenta and sautéed mushrooms. The result is a dinner of comfort and joy.",
    "processing_complete": true,
    "image": "https://static01.nyt.com/images/2021/07/28/dining/hm-cumin-tofu/hm-cumin-tofu-articleLarge.jpg",
    "parsed_instructions": [
        "In a large salad bowl, place the washed and torn lettuce pieces.",
        "In a small bowl, whisk together the extra virgin olive oil and fresh lemon juice until well combined.",
        "Drizzle the lemon and olive oil dressing over the lettuce in the salad bowl.",
        "Toss the lettuce gently to ensure it is evenly coated with the dressing.",
        "Sprinkle the shredded Parmesan cheese over the top of the dressed lettuce.",
        "Season with salt and freshly ground black pepper to taste.",
        "Serve immediately and enjoy the fresh, simple flavors of this lettuce salad."
    ],
    "parsed_ingredients": [
        {
            "aisle": "dairy",
            "parsed_ingredient": "Whole milk",
            "quantity": 1.94,
            "raw_unit_of_measure": "cups",
            "raw_ingredient": "2 cups minus 3 tablespoons whole milk",
            "parsed_unit_of_measure": "Cups"
        },
        {
            "aisle": "other",
            "parsed_ingredient": "Salt",
            "quantity": 1,
            "raw_unit_of_measure": "teaspoon",
            "raw_ingredient": "1 teaspoon salt, or to taste",
            "parsed_unit_of_measure": "Teaspoon"
        },
        {
            "aisle": "other",
            "parsed_ingredient": "Polenta or cornmeal",
            "quantity": 1.75,
            "raw_unit_of_measure": "cups",
            "raw_ingredient": "1 3/4 cups polenta or cornmeal",
            "parsed_unit_of_measure": "Cups"
        },
        {
            "aisle": "dairy",
            "parsed_ingredient": "Unsalted butter",
            "quantity": 4,
            "raw_unit_of_measure": "tablespoons",
            "raw_ingredient": "4 tablespoons unsalted butter, or to taste",
            "parsed_unit_of_measure": "Tablespoons"
        },
        {
            "aisle": "dairy",
            "parsed_ingredient": "Grated Parmesan",
            "quantity": 1,
            "raw_unit_of_measure": "tablespoon",
            "raw_ingredient": "1 tablespoon grated Parmesan, or to taste",
            "parsed_unit_of_measure": "Tablespoon"
        },
        {
            "aisle": "produce",
            "parsed_ingredient": "Dried porcini mushrooms",
            "quantity": 0.5,
            "raw_unit_of_measure": "ounce",
            "raw_ingredient": "1/2 ounce dried porcini mushrooms",
            "parsed_unit_of_measure": "Ounce"
        },
        {
            "aisle": "dairy",
            "parsed_ingredient": "Cold unsalted butter",
            "quantity": 5,
            "raw_unit_of_measure": "tablespoons",
            "raw_ingredient": "5 tablespoons cold unsalted butter, cut into pats, divided",
            "parsed_unit_of_measure": "Tablespoons"
        },
        {
            "aisle": "produce",
            "parsed_ingredient": "Garlic",
            "quantity": 1,
            "raw_unit_of_measure": "clove",
            "raw_ingredient": "1 clove garlic, peeled and minced",
            "parsed_unit_of_measure": "Clove"
        },
        {
            "aisle": "produce",
            "parsed_ingredient": "Fresh mushrooms, wild or cultivated, sliced thin",
            "quantity": 8,
            "raw_unit_of_measure": "ounces",
            "raw_ingredient": "8 ounces fresh mushrooms, wild or cultivated, sliced thin",
            "parsed_unit_of_measure": "Ounces"
        },
        {
            "aisle": "produce",
            "parsed_ingredient": "Fresh thyme leaves",
            "quantity": 1,
            "raw_unit_of_measure": "teaspoon",
            "raw_ingredient": "1 teaspoon fresh thyme leaves",
            "parsed_unit_of_measure": "Teaspoon"
        },
        {
            "aisle": "ethnic",
            "parsed_ingredient": "Soy sauce",
            "quantity": 1,
            "raw_unit_of_measure": "tablespoon",
            "raw_ingredient": "1 tablespoon soy sauce",
            "parsed_unit_of_measure": "Tablespoon"
        },
        {
            "aisle": "dairy",
            "parsed_ingredient": "Heavy cream",
            "quantity": 1,
            "raw_unit_of_measure": "tablespoon",
            "raw_ingredient": "1 tablespoon heavy cream",
            "parsed_unit_of_measure": "Tablespoon"
        },
        {
            "aisle": "ethnic",
            "parsed_ingredient": "Extra-virgin olive oil",
            "quantity": 1,
            "raw_unit_of_measure": "tablespoon",
            "raw_ingredient": "1 tablespoon extra-virgin olive oil",
            "parsed_unit_of_measure": "Tablespoon"
        },
        {
            "aisle": "other",
            "parsed_ingredient": "Freshly ground black pepper",
            "quantity": 0,
            "raw_unit_of_measure": "",
            "raw_ingredient": "Freshly ground black pepper",
            "parsed_unit_of_measure": ""
        }
    ],
    "favicon": "https://static01.nyt.com/applications/cooking/bed7145/assets/apple-touch-icon-152x152-precomposed.png"
    };

export const testRecipeInternal = {
    id: '2LH2Xkv6hxsFD4lKPlmt',
    updated_at: '2024-09-29T21:38:50.166795+00:00',
    recipe_id: 'D163OsUHj1nujYfB8XS7',
    recipe_type: 'native',
    queued: false,
    url: '<HOME>/recipe/D163OsUHj1nujYfB8XS7',
    headline: 'Arancini (Stuffed Italian Rice Balls)',
    domain: '<HOME>',
    parsed_instructions: [
        "In a large salad bowl, place the washed and torn lettuce pieces.",
        "In a small bowl, whisk together the extra virgin olive oil and fresh lemon juice until well combined.",
        "Drizzle the lemon and olive oil dressing over the lettuce in the salad bowl.",
        "Toss the lettuce gently to ensure it is evenly coated with the dressing.",
        "Sprinkle the shredded Parmesan cheese over the top of the dressed lettuce.",
        "Season with salt and freshly ground black pepper to taste.",
        "Serve immediately and enjoy the fresh, simple flavors of this lettuce salad."
    ],
    parsed_ingredients: [
        {
        aisle: 'other',
        calories: 151.2,
        quantity: 1.75,
        nutrient_id: '172884',
        fat_calories: 45.36,
        carbs_calories: 59.303999999999995,
        raw_ingredient: '1¾ cups low-sodium homemade chicken stock or store-bought broth',
        unit_of_measure: 'cups',
        protein_calories: 42.336000000000006,
        parsed_ingredient: 'low-sodium homemade chicken stock or store-bought broth',
        nutrient_description: 'chicken stock',
        nutrition_search_term: 'chicken stock',
        nutrient_original_description: 'Soup, stock, chicken, home-prepared',
        approximate_weight_of_full_item_in_grams: 420
        },
        {
        aisle: 'dairy',
        calories: 200.76,
        quantity: 2,
        nutrient_id: '173430',
        fat_calories: 204.3972,
        carbs_calories: 0.0672,
        raw_ingredient: '2 Tbsp. unsalted butter, divided',
        unit_of_measure: 'Tbsp.',
        protein_calories: 0.9520000000000001,
        parsed_ingredient: 'unsalted butter, divided',
        nutrient_description: 'unsalted butter',
        nutrition_search_term: 'unsalted butter',
        nutrient_original_description: 'Butter, without salt',
        approximate_weight_of_full_item_in_grams: 28
        },
        {
        aisle: 'produce',
        calories: 26.6,
        quantity: 1,
        nutrient_id: '2345315',
        fat_calories: 0.504,
        carbs_calories: 23.688000000000002,
        raw_ingredient: '1 small onion, finely chopped',
        unit_of_measure: 'small',
        protein_calories: 2.408,
        parsed_ingredient: 'onion, finely chopped',
        nutrient_description: 'onions',
        nutrition_search_term: 'onion',
        nutrient_original_description: 'Onions, raw',
        approximate_weight_of_full_item_in_grams: 70
        },
        {
        aisle: 'produce',
        calories: 8.94,
        quantity: 1,
        nutrient_id: '169230',
        fat_calories: 0.27,
        carbs_calories: 7.9344,
        raw_ingredient: '1 garlic clove, thinly sliced',
        unit_of_measure: 'clove',
        protein_calories: 1.5264000000000002,
        parsed_ingredient: 'garlic clove, thinly sliced',
        nutrient_description: 'garlic',
        nutrition_search_term: 'garlic',
        nutrient_original_description: 'Garlic, raw',
        approximate_weight_of_full_item_in_grams: 6
        },
        {
        aisle: 'ethnic',
        calories: 306,
        quantity: 0.5,
        nutrient_id: '-80',
        fat_calories: 0,
        carbs_calories: 283.392,
        raw_ingredient: '½ cup carnaroli rice or arborio rice',
        unit_of_measure: 'cup',
        protein_calories: 22.968000000000004,
        parsed_ingredient: 'carnaroli rice or arborio rice',
        nutrient_description: 'arborio rice',
        nutrition_search_term: 'carnaroli rice',
        nutrient_original_description: 'Arborio rice',
        approximate_weight_of_full_item_in_grams: 90
        },
        {
        aisle: 'ethnic',
        calories: 0,
        quantity: 0.75,
        nutrient_id: '0',
        fat_calories: 0,
        carbs_calories: 0,
        raw_ingredient: '¾ tsp. Diamond Crystal or ½ tsp. Morton kosher salt, plus more',
        unit_of_measure: 'tsp.',
        protein_calories: 0,
        parsed_ingredient: 'Diamond Crystal or Morton kosher salt',
        nutrient_description: 'kosher salt',
        nutrition_search_term: 'kosher salt',
        nutrient_original_description: 'Kosher salt',
        approximate_weight_of_full_item_in_grams: 4.5
        },
        {
        aisle: 'other',
        calories: 49.2,
        quantity: 0.25,
        nutrient_id: '2346264',
        fat_calories: 0,
        carbs_calories: 6.24,
        raw_ingredient: '¼ cup dry white wine',
        unit_of_measure: 'cup',
        protein_calories: 0.168,
        parsed_ingredient: 'dry white wine',
        nutrient_description: 'white wine',
        nutrition_search_term: 'white wine',
        nutrient_original_description: 'Wine, table, white',
        approximate_weight_of_full_item_in_grams: 60
        },
        {
        aisle: 'dairy',
        calories: 109.76,
        quantity: 1,
        nutrient_id: '170848',
        fat_calories: 63,
        carbs_calories: 3.6064000000000003,
        raw_ingredient: '1 oz. finely grated Parmesan cheese (about ½ cup)',
        unit_of_measure: 'oz.',
        protein_calories: 40.04,
        parsed_ingredient: 'finely grated Parmesan cheese',
        nutrient_description: 'parmesan cheese',
        nutrition_search_term: 'Parmesan cheese',
        nutrient_original_description: 'Cheese, parmesan, hard',
        approximate_weight_of_full_item_in_grams: 28
        },
        {
        aisle: 'dairy',
        calories: 102,
        quantity: 2,
        nutrient_id: '2340999',
        fat_calories: 97.41599999999998,
        carbs_calories: 3.4079999999999995,
        raw_ingredient: '2 Tbsp. heavy cream',
        unit_of_measure: 'Tbsp.',
        protein_calories: 3.4079999999999995,
        parsed_ingredient: 'heavy cream',
        nutrient_description: 'heavy cream',
        nutrition_search_term: 'heavy cream',
        nutrient_original_description: 'Cream, heavy',
        approximate_weight_of_full_item_in_grams: 30
        },
        {
        aisle: 'produce',
        calories: 0.94,
        quantity: 1,
        nutrient_id: '167749',
        fat_calories: 0.05399999999999999,
        carbs_calories: 1.28,
        raw_ingredient: '1 tsp. finely grated lemon zest',
        unit_of_measure: 'tsp.',
        protein_calories: 0.12,
        parsed_ingredient: 'finely grated lemon zest',
        nutrient_description: 'lemon peel',
        nutrition_search_term: 'lemon zest',
        nutrient_original_description: 'Lemon peel, raw',
        approximate_weight_of_full_item_in_grams: 2
        },
        {
        aisle: 'produce',
        calories: 1.275,
        quantity: 0.5,
        nutrient_id: '2344677',
        fat_calories: 0.04725000000000001,
        carbs_calories: 1.686,
        raw_ingredient: '½ Tbsp. fresh lemon juice',
        unit_of_measure: 'Tbsp.',
        protein_calories: 0.135,
        parsed_ingredient: 'fresh lemon juice',
        nutrient_description: 'lemon juice',
        nutrition_search_term: 'lemon juice',
        nutrient_original_description: 'Lemon juice, 100%, canned or bottled',
        approximate_weight_of_full_item_in_grams: 7.5
        },
        {
        aisle: 'ethnic',
        calories: 2.8865,
        quantity: 0.5,
        nutrient_id: '170931',
        fat_calories: 0.33740999999999993,
        carbs_calories: 2.9417,
        raw_ingredient: '½ tsp. freshly ground black pepper, plus more',
        unit_of_measure: 'tsp.',
        protein_calories: 0.47794,
        parsed_ingredient: 'freshly ground black pepper',
        nutrient_description: 'black pepper',
        nutrition_search_term: 'black pepper',
        nutrient_original_description: 'Spices, pepper, black',
        approximate_weight_of_full_item_in_grams: 1.15
        },
        {
        aisle: 'dairy',
        calories: 126.65,
        quantity: 1.5,
        nutrient_id: '329370',
        fat_calories: 78.03,
        carbs_calories: 7.548000000000001,
        raw_ingredient: '1½ oz. low-moisture mozzarella cheese, cut into ⅓" pieces',
        unit_of_measure: 'oz.',
        protein_calories: 40.29,
        parsed_ingredient: 'low-moisture mozzarella cheese, cut into ⅓" pieces',
        nutrient_description: 'mozzarella cheese',
        nutrition_search_term: 'mozzarella cheese',
        nutrient_original_description: 'Cheese, mozzarella, low moisture, part-skim',
        approximate_weight_of_full_item_in_grams: 42.5
        },
        {
        aisle: 'bakery',
        calories: 214.2,
        quantity: 1,
        nutrient_id: '-37',
        fat_calories: 173.55599999999998,
        carbs_calories: 0,
        raw_ingredient: '1 cup panko',
        unit_of_measure: 'cup',
        protein_calories: 34.296,
        parsed_ingredient: 'panko',
        nutrient_description: 'pancetta',
        nutrition_search_term: 'panko',
        nutrient_original_description: 'Pancetta',
        approximate_weight_of_full_item_in_grams: 60
        },
        {
        aisle: 'bakery',
        calories: 109.2,
        quantity: 0.25,
        nutrient_id: '168894',
        fat_calories: 2.6460000000000004,
        carbs_calories: 91.572,
        raw_ingredient: '¼ cup all-purpose flour',
        unit_of_measure: 'cup',
        protein_calories: 12.395999999999999,
        parsed_ingredient: 'all-purpose flour',
        nutrient_description: 'white all-purpose flour',
        nutrition_search_term: 'all-purpose flour',
        nutrient_original_description: 'Wheat flour, white, all-purpose, enriched, bleached',
        approximate_weight_of_full_item_in_grams: 30
        },
        {
        aisle: 'dairy',
        calories: 69,
        quantity: 1,
        nutrient_id: '170893',
        fat_calories: 45.315,
        carbs_calories: 1.66,
        raw_ingredient: '1 large egg',
        unit_of_measure: 'large',
        protein_calories: 21.94,
        parsed_ingredient: 'large egg',
        nutrient_description: 'egg',
        nutrition_search_term: 'egg',
        nutrient_original_description: 'Egg, whole, raw, frozen, salted, pasteurized',
        approximate_weight_of_full_item_in_grams: 50
        },
        {
        aisle: 'other',
        calories: 5304,
        quantity: 3,
        nutrient_id: '172336',
        fat_calories: 5400,
        carbs_calories: 0,
        raw_ingredient: 'Canola or vegetable oil (for frying; about 3 cups)',
        unit_of_measure: 'cups',
        protein_calories: 0,
        parsed_ingredient: 'Canola or vegetable oil (for frying)',
        nutrient_description: 'canola oil',
        nutrition_search_term: 'canola oil',
        nutrient_original_description: 'Oil, canola',
        approximate_weight_of_full_item_in_grams: 600
        }
    ],
    nutrition: {
        calories: 6782.6115,
        fat_calories: 6110.93286,
        carbs_calories: 494.32770000000005,
        protein_calories: 223.46134
    },
    processing_complete: true,
    domain_description: 'Mise En Place'
    };

export const exampleIdeas = [
        {
            id: "7d9e2f4b-6a1c-8h3i-4j5k-9l0m1n2o3p4",
            meal_id: "5e593820-00b2-4307-b9b8-5fd8844279b9",
            hydrated: false,
            idea_string: "Roasted chicken with sweet potatoes and green beans",
            recipe_ideas: [
                {
                    id: "8f4e1a2b-3c5d-4e6f-7g8h-9i0j1k2l3m4n",
                    function: "find_internet_recipe",
                    value: "Herb roasted chicken"
                },
                {
                    id: "a1b2c3d4-e5f6-7g8h-9i0j-k1l2m3n4o5p",
                    function: "build_simple_ai_recipe", 
                    value: "Roasted sweet potatoes"
                },
                {
                    id: "p9o8n7m6-l5k4-j3i2-h1g0-f1e2d3c4b5a",
                    function: "build_simple_ai_recipe",
                    value: "Steamed green beans"
                }
            ]
        },
        {
            id: "b5c6d7e8-f9g0-h1i2-j3k4-l5m6n7o8p9q",
            meal_id: "5e593820-00b2-4307-b9b8-5fd8844279b9", 
            hydrated: true,
            idea_string: "Greek salad with grilled chicken",
            recipe_ideas: [
                {
                    id: "q1w2e3r4-t5y6-u7i8-o9p0-a1s2d3f4g5h",
                    function: "find_internet_recipe",
                    value: "recipe_123",
                    response: {
                        primary_recipe: {
                            recipe_id: 'xa1b2c3d4-e5f6-7g8h-9i0j-k1l2m3n4o5p',
                            url: null,
                            headline: 'Herb Roasted Chicken',
                            domain: 'https://www.food.com',
                            recipe_type: 'external',
                            instructions: [
                                'Preheat oven to 400°F (200°C)',
                                'Mince garlic and chop rosemary finely',
                                'Season chicken breasts with salt and pepper',
                                'Rub chicken with garlic and rosemary mixture',
                                'Place on baking sheet and roast for 25-30 minutes until cooked through'
                            ],
                            prep_time_minutes: 10,
                            cook_time_minutes: 30,
                            total_time_minutes: 40,
                            servings: 4,
                            parsed_ingredients: [
                                {
                                    aisle: 'meat',
                                    calories: 231.0,
                                    quantity: 4,
                                    nutrient_id: '171445',
                                    fat_calories: 135.0,
                                    carbs_calories: 0,
                                    raw_ingredient: '4 chicken breasts',
                                    unit_of_measure: '',
                                    protein_calories: 96.0,
                                    parsed_ingredient: 'chicken breast',
                                    nutrient_description: 'chicken breast',
                                    nutrition_search_term: 'chicken breast',
                                    nutrient_original_description: 'Chicken breast, raw',
                                    approximate_weight_of_full_item_in_grams: 150
                                },
                                {
                                    aisle: 'produce',
                                    calories: 4.0,
                                    quantity: 2,
                                    nutrient_id: '171322',
                                    fat_calories: 0.36,
                                    carbs_calories: 2.88,
                                    raw_ingredient: '2 sprigs fresh rosemary',
                                    unit_of_measure: 'sprig',
                                    protein_calories: 0.76,
                                    parsed_ingredient: 'fresh rosemary',
                                    nutrient_description: 'rosemary',
                                    nutrition_search_term: 'rosemary',
                                    nutrient_original_description: 'Rosemary, fresh',
                                    approximate_weight_of_full_item_in_grams: 5
                                },
                                {
                                    aisle: 'produce',
                                    calories: 2.0,
                                    quantity: 3,
                                    nutrient_id: '171323',
                                    fat_calories: 0.18,
                                    carbs_calories: 1.44,
                                    raw_ingredient: '3 cloves garlic, minced',
                                    unit_of_measure: 'clove',
                                    protein_calories: 0.38,
                                    parsed_ingredient: 'garlic',
                                    nutrient_description: 'garlic',
                                    nutrition_search_term: 'garlic',
                                    nutrient_original_description: 'Garlic, raw',
                                    approximate_weight_of_full_item_in_grams: 3
                                }
                            ]
                        }
                    }
                },
                {
                    id: "z9x8c7v6-b5n4-m3l2-k1j0-h1g2f3d4s5a",
                    function: "build_simple_ai_recipe",
                    value: "Greek salad",
                    response: {
                        primary_recipe: {
                            recipe_id: 'xa1b2c3d4-e5f6-7g8h-9i0j-k1l2m3n4o5p',
                            url: null,
                            headline: 'Greek Salad',
                            domain: '<HOME>',
                            recipe_type: 'native',
                            parsed_ingredients: [
                                {
                                    aisle: 'produce',
                                    calories: 8.45,
                                    quantity: 1,
                                    nutrient_id: '169228', 
                                    fat_calories: 0,
                                    carbs_calories: 7.92,
                                    raw_ingredient: '1 cucumber, sliced',
                                    unit_of_measure: '',
                                    protein_calories: 1.64,
                                    parsed_ingredient: 'cucumber',
                                    nutrient_description: 'cucumber',
                                    nutrition_search_term: 'cucumber',
                                    nutrient_original_description: 'Cucumber, with peel, raw',
                                    approximate_weight_of_full_item_in_grams: 200
                                },
                                {
                                    aisle: 'produce',
                                    calories: 24.0,
                                    quantity: 1,
                                    nutrient_id: '169322',
                                    fat_calories: 2.16,
                                    carbs_calories: 18.72,
                                    raw_ingredient: '1 green bell pepper, sliced',
                                    unit_of_measure: '',
                                    protein_calories: 3.12,
                                    parsed_ingredient: 'green bell pepper',
                                    nutrient_description: 'green bell pepper',
                                    nutrition_search_term: 'green bell pepper',
                                    nutrient_original_description: 'Peppers, sweet, green, raw',
                                    approximate_weight_of_full_item_in_grams: 120
                                },
                                {
                                    aisle: 'produce',
                                    calories: 32.0,
                                    quantity: 0.5,
                                    nutrient_id: '170000',
                                    fat_calories: 0,
                                    carbs_calories: 29.44,
                                    raw_ingredient: '1/2 red onion, thinly sliced',
                                    unit_of_measure: '',
                                    protein_calories: 2.56,
                                    parsed_ingredient: 'red onion',
                                    nutrient_description: 'red onion',
                                    nutrition_search_term: 'red onion',
                                    nutrient_original_description: 'Onions, raw',
                                    approximate_weight_of_full_item_in_grams: 80
                                },
                                {
                                    aisle: 'produce',
                                    calories: 27.0,
                                    quantity: 1,
                                    nutrient_id: '170457',
                                    fat_calories: 2.97,
                                    carbs_calories: 21.06,
                                    raw_ingredient: '1 cup cherry tomatoes, halved',
                                    unit_of_measure: 'cup',
                                    protein_calories: 2.97,
                                    parsed_ingredient: 'cherry tomatoes',
                                    nutrient_description: 'cherry tomatoes',
                                    nutrition_search_term: 'cherry tomatoes',
                                    nutrient_original_description: 'Tomatoes, cherry, raw',
                                    approximate_weight_of_full_item_in_grams: 150
                                },
                                {
                                    aisle: 'ethnic',
                                    calories: 72.5,
                                    quantity: 0.5,
                                    nutrient_id: '171323',
                                    fat_calories: 65.25,
                                    carbs_calories: 3.625,
                                    raw_ingredient: '1/2 cup kalamata olives',
                                    unit_of_measure: 'cup',
                                    protein_calories: 3.625,
                                    parsed_ingredient: 'kalamata olives',
                                    nutrient_description: 'kalamata olives',
                                    nutrition_search_term: 'kalamata olives',
                                    nutrient_original_description: 'Olives, ripe, canned (small-extra large)',
                                    approximate_weight_of_full_item_in_grams: 50
                                },
                                {
                                    aisle: 'dairy',
                                    calories: 99.4,
                                    quantity: 3.5,
                                    nutrient_id: '171028',
                                    fat_calories: 74.55,
                                    carbs_calories: 3.976,
                                    raw_ingredient: '3.5 ounces feta cheese, cubed',
                                    unit_of_measure: 'ounces',
                                    protein_calories: 20.874,
                                    parsed_ingredient: 'feta cheese',
                                    nutrient_description: 'feta cheese',
                                    nutrition_search_term: 'feta cheese',
                                    nutrient_original_description: 'Cheese, feta',
                                    approximate_weight_of_full_item_in_grams: 100
                                },
                                {
                                    aisle: 'other',
                                    calories: 238.68,
                                    quantity: 2,
                                    nutrient_id: '171413',
                                    fat_calories: 238.68,
                                    carbs_calories: 0,
                                    raw_ingredient: '2 tablespoons olive oil',
                                    unit_of_measure: 'tablespoons',
                                    protein_calories: 0,
                                    parsed_ingredient: 'olive oil',
                                    nutrient_description: 'olive oil',
                                    nutrition_search_term: 'olive oil',
                                    nutrient_original_description: 'Oil, olive, salad or cooking',
                                    approximate_weight_of_full_item_in_grams: 27
                                }
                            ],
                            image: null,
                            nutrition: {
                                calories: 502.03,
                                fat_calories: 383.61,
                                carbs_calories: 84.741,
                                protein_calories: 34.789
                            },
                            processing_complete: true,
                            description: 'A classic Mediterranean dish featuring fresh vegetables, briny olives, and creamy feta cheese that comes together with a simple lemon-olive oil dressing. This refreshing salad bursts with the flavors of Greece and is perfect as a side or a light main dish.',
                            instructions: [
                                'In a large mixing bowl, combine the cucumber, bell pepper, red onion, cherry tomatoes, and olives.',
                                'In a small bowl, whisk together the olive oil, lemon juice, dried oregano, salt, and black pepper to create the dressing.',
                                'Pour the dressing over the vegetables and toss gently to coat everything evenly.',
                                'Add the cubed feta cheese to the salad and gently toss again.',
                                'Serve immediately or refrigerate for up to 30 minutes to let the flavors meld.'
                            ],
                            serving_size: 2
                        }
    
                    }
                }
            ]
        }
    ];
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useListMealsQuery, useAddRecipeToMealMutation } from '../../store/apiSlice';
import { RadioGroup, RadioGroupItem } from '../../components/Radio';
import { Label } from '../../components/Label';
import { SheetHeader, SheetTitle } from '../../components/Sheet';
import RecipeList from '../recipeLibrary/RecipeList';
import { setRecipeLibrarySelectedMealId } from './mealPlanSlice';
import { formatDate, sortMeals } from '../../utils';

const RecipeLibrarySheetContent = () => {
  const dispatch = useDispatch();
  const [addRecipeToMeal] = useAddRecipeToMealMutation();
  const selectedMealId = useSelector(state => state.mealPlan.sheets.recipeLibrary.selectedMealId);
  const meal_plan_id = useSelector(state => state.mealPlan.meal_plan_id);
  const { data, error, isLoading } = useListMealsQuery({ mealPlanId: meal_plan_id });
  const meals = data?.meals || [];

  const [additionalActions, setAdditionalActions] = useState([]);

  useEffect(() => {
    if (selectedMealId) {
      setAdditionalActions([{
        text: "Add to Meal",
        handler: async (recipe) => {
          try {
            await addRecipeToMeal({
              meal_id: selectedMealId,
              recipe_id: recipe.recipe_id,
              multiplier: 1
            });
          } catch (error) {
            console.error('Failed to add recipe to meal:', error);
          }
        }
      }]);
    } else {
      setAdditionalActions([]);
    }
  }, [selectedMealId, addRecipeToMeal]);

  const getRecipesList = (recipes) => {
    if (!recipes || recipes.length === 0) {
      return "No recipes scheduled yet";
    }
    return recipes.map(recipe => recipe.recipe.headline).join(", ");
  };

  const getMealName = (meal) => {
    const formattedDate = formatDate(meal.date);
    const mealType = meal.meal_type.charAt(0).toUpperCase() + meal.meal_type.slice(1);
    return `${formattedDate} - ${mealType}`;
  };

  const filteredAndSortedMeals = React.useMemo(() => {
    if (!meals) return [];
    return sortMeals(meals.filter(meal => meal.primary_meal === null));
  }, [meals]);

  return (
    <>
      <SheetHeader>
        <SheetTitle>Recipe Library</SheetTitle>
      </SheetHeader>
      <div className="py-4">
        <div className="text-2xl font-bold mb-4">Select a meal</div>
        {isLoading ? (
          <div>Loading meals...</div>
        ) : error ? (
          <div>Error loading meals: {error.message}</div>
        ) : (
          <RadioGroup 
            value={selectedMealId} 
            onValueChange={(value) => {
              dispatch(setRecipeLibrarySelectedMealId(value));
            }}
            className="flex flex-wrap gap-4"
          >
            {filteredAndSortedMeals.map((meal) => (
              <div key={meal.id} className="flex flex-col space-y-1 min-w-xs">
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value={meal.id} id={meal.id} />
                  <Label htmlFor={meal.id} className="font-bold">
                    {getMealName(meal)}
                  </Label>
                </div>
                <span className="text-sm text-gray-600 ml-7">
                  {getRecipesList(meal.recipes)}
                </span>
              </div>
            ))}
          </RadioGroup>
        )}
        <div className="mt-4">
          <RecipeList additionalActions={additionalActions} />
        </div>
      </div>
    </>
  );
};

export default RecipeLibrarySheetContent; 
import { supabase } from '../lib/supabase-client';
import { BASE_WS_URL } from '../constants';

class WebSocketGenerationService {
    constructor({
        endpoint,
        payload,
        onOpen,
        onMessage,
        onError,
        onClose
    }) {
        this.endpoint = `${BASE_WS_URL}/${endpoint}`;
        this.payload = payload;
        this.onOpen = onOpen;
        this.onMessage = onMessage;
        this.onError = onError || this.defaultOnError;
        this.onClose = onClose;
        this.ws = null;
    }

    defaultOnError(error) {
        console.error('WebSocket error:', error);
    }

    async connect() {
        try {
            const { data: { session } } = await supabase.auth.getSession();
            const token = session?.access_token;
            this.ws = new WebSocket(this.endpoint);

            this.ws.onopen = () => {
                console.log('WebSocket connected to:', this.endpoint);
                
                const payloadWithToken = {
                    payload: this.payload,
                    token: token
                };
                console.log("Sending payload with token:", payloadWithToken);
                this.ws.send(JSON.stringify(payloadWithToken));

                if (this.onOpen) {
                    this.onOpen();
                }
            };

            this.ws.onmessage = (event) => {
                try {
                    const data = JSON.parse(event.data);
                    
                    // Handle error messages
                    if (data.error) {
                        this.onError(new Error(data.error));
                        this.disconnect();
                        return;
                    }

                    // Handle end of stream
                    if (data.end_of_stream) {
                        if (this.onClose) {
                            this.onClose();
                        }
                        this.disconnect();
                        return;
                    }

                    // Handle normal messages
                    this.onMessage(data);
                } catch (error) {
                    this.onError(error);
                    this.disconnect();
                }
            };

            this.ws.onerror = (error) => {
                this.onError(error);
            };

            this.ws.onclose = () => {
                console.log('WebSocket connection closed');
                this.ws = null;
                if (this.onClose) {
                    this.onClose();
                }
            };
        } catch (error) {
            this.onError(error);
        }
    }

    disconnect() {
        if (this.ws) {
            this.ws.close();
            this.ws = null;
        }
    }
}

export default WebSocketGenerationService; 
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatDate } from '../../utils';
import RecipeItem from '../../components/RecipeItem';
import { openAdjustQuantityModal, updateMealNote, setSelectedMealIdeaId } from './mealPlanSlice';
import { useRemoveRecipeFromMealMutation, useGetMealPlanQuery } from '../../store/apiSlice';
import { Badge } from 'src/components/Badge';
import { useGetUserQuery } from '../../store/apiSlice';
import NutritionTable from './NutritionTable';
import { Input } from '../../components/Input';
import { Carousel } from 'src/components/Carousel';
import { ControlledToggleableCard } from 'src/components/ToggleableCard';
import MealIdea from './MealIdea';
import { motion } from 'framer-motion';

const MealPlanMeal = ({ meal, leftovers }) => {
  console.log('meal id:', meal.id, 'leftovers:', leftovers);
  const dispatch = useDispatch();
  const showParticipants = useSelector(state => state.mealPlan.showParticipants);
  const showNutrition = useSelector(state => state.mealPlan.showNutrition);
  const [removeRecipeFromMeal] = useRemoveRecipeFromMealMutation();
  const { meal_type, recipes, id: mealId } = meal;
  const { data: user } = useGetUserQuery();
  const { data: mealPlan } = useGetMealPlanQuery(meal.meal_plan_id);
  const householdDict = user?.household?.reduce((acc, member) => {
      acc[member.id] = member;
      return acc;
  }, {}) || {};
  const mealNote = useSelector(state => state.mealPlan.mealNotes[meal.id] || '');
  
  // Use meal ideas from the meal plan query
  const mealSpecificIdeas = useMemo(() => {
    const ideas = mealPlan?.meal_ideas?.filter(idea => idea.meal_id === meal.id) || [];
    console.log("Meal specific ideas", ideas);
    return ideas;
  }, [mealPlan, meal.id]);

  const selectedMealIdeaId = useSelector(state => state.mealPlan.selectedMealIdeaId);
  const showMealIdea = mealSpecificIdeas.some(idea => idea.id === selectedMealIdeaId);

  const handleOpenAdjustQuantityModal = (recipe) => {
    dispatch(openAdjustQuantityModal({
      recipeId: recipe.recipe.recipe_id,
      mealId: mealId,
      initialMultiplier: recipe.multiplier,
    }));
  };

  const handleRemoveRecipeFromMeal = async (recipeId) => {
    try {
      await removeRecipeFromMeal({ meal_id: mealId, recipe_id: recipeId });
    } catch (error) {
      console.error('Failed to remove recipe from meal:', error);
      // Handle error (e.g., show a notification to the user)
    }
  };

  const handleNoteChange = (e) => {
    dispatch(updateMealNote({
      mealId: meal.id,
      note: e.target.value
    }));
  };

  const handleMealIdeaToggle = (mealIdea, isActive) => {
    dispatch(setSelectedMealIdeaId(isActive ? mealIdea.id : null));
  };

  const renderRecipients = () => {
    if (!showParticipants || !meal.meal_recipients?.length) {
      return null;
    }

    const recipientNames = meal.meal_recipients
      .map(recipientId => householdDict[recipientId]?.customer_facing_name)
      .filter(name => name)
      .join(', ');

    return recipientNames ? (
      <span className="text-xs">Meal for: {recipientNames}</span>
    ) : null;
  };

  // Early return for primary meals
  if (meal.primary_meal) {
    return (
      <div className="max-w-full pb-4">
        <div className="flex flex-col gap-2">
          <div className="text-lg">{meal_type.charAt(0).toUpperCase() + meal_type.slice(1)}</div>
          {renderRecipients()}
          <Badge variant="destructive" className="w-fit">Leftover for {formatDate(meal.primary_meal.date)}'s {meal.primary_meal.meal_type}</Badge>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-full pb-4">
      <div className="flex flex-col gap-2">
        <div className="text-lg">{meal_type.charAt(0).toUpperCase() + meal_type.slice(1)}</div>
        {renderRecipients()}
        <Input
          variant="clean"
          placeholder={`Any notes for ${meal_type}...`}
          value={mealNote}
          onChange={handleNoteChange}
          className="text-sm"
        />
        {showNutrition && (
          <NutritionTable 
            meal={meal}
            leftovers={leftovers}
            recipes={recipes}
            householdDict={householdDict}
          />
        )}
        {recipes.length === 0 ? (
          null
        ) : (
          <Carousel>
            {recipes.map((recipeData) => (
              <RecipeItem
                key={recipeData.recipe.recipe_id}
                recipe={recipeData.recipe}
                quantity={recipeData.multiplier}
                className="mr-2"
                actions={[
                  {
                    actionText: 'Remove',
                    actionHandler: () => handleRemoveRecipeFromMeal(recipeData.recipe.recipe_id)
                  },
                  {
                    actionText: 'Adjust quantity',
                    actionHandler: () => handleOpenAdjustQuantityModal(recipeData)
                  }
                ]}
              />
            ))}
          </Carousel>
        )}
        
        {mealSpecificIdeas.length > 0 && (
          <Carousel>
            {mealSpecificIdeas.map((mealIdea) => (
              <motion.div
                key={mealIdea.id}
                initial={{ opacity: 0, scale: 0.9, y: -20 }}
                animate={{ opacity: 1, scale: 1, y: 0 }}
                exit={{ opacity: 0, scale: 0.9, y: -20 }}
                transition={{ duration: 0.4, ease: "easeOut" }}
              >
                <ControlledToggleableCard
                  maxWidth="m"
                  isActive={selectedMealIdeaId === mealIdea.id}
                  onToggle={(isActive) => handleMealIdeaToggle(mealIdea, isActive)}
                  className="mr-2 max-w-xs line-clamp-2"
                >
                  <span className="text-sm">{mealIdea.idea_string}</span>
                </ControlledToggleableCard>
              </motion.div>
            ))}
          </Carousel>
        )}
        {showMealIdea && <MealIdea mealId={meal.id} />}
      </div>
    </div>
  );
};

export default MealPlanMeal;
